import React, { useContext, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { LuPlus } from "react-icons/lu";
import { FaPlus } from 'react-icons/fa';
import { MdKeyboardBackspace } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import { IoMdClose } from 'react-icons/io';
import '../../../User/Pages/CreateProfile/Language.css'
import Select from "react-select";
import { EventButton } from '../../../User/component/ReUseComps/Button';
import { useNavigate } from 'react-router-dom';
import { COMPANY_URLS } from '../../../Utils/Company_App_Urls'
import axios from 'axios';
import { errorPopup, successPopup } from '../../../Utils/ToasterMessage';
// import Editor from 'react-simple-wysiwyg';
import useHtmlToText from '../../Component/CustomHooks/convertHtmlToText';
import CompanyContext from '../../Component/ContextAPI/CompanyContext';
import { Editor } from "primereact/editor";
import './JobPost.css'

export default function JobPost() {
    const navigate = useNavigate()
    const { setNeedTorender } = useContext(CompanyContext);
    const [minWordsInJobSummary, setMinWordsInJobSummary] = useState('')
    const [moreLanguageOption, setMoreLanguageOption] = useState([{ languageFromMenu: '', proficiency: '' }]);
    const [requiredSkill, setRequiredSkill] = useState([]);
    const [jobPostDetails, setJobPostDetails] = useState({ jobTitle: '', employmentType: '', jobDescription: '', shift: '', workPlace: '', education: '', experience: '', jobLocation: '', noOfOpening: '', gender: '', ageRange: '', jobSummary: '' })
    const [allJobList, setAllJobList] = useState([])
    const [error, setError] = useState({
        errorStatus: true,
        jobTitle: '',
        jobLocation:'',
        jobSummary: '',
        employmentType: '',
        jobDescription: '',
        shift: '',
        workPlace: '',
        education: '',
        experience: '',
        noOfOpening: '',
        gender: '',
        ageRange: '',
        skill: '',
        language: '',
        salaryType: '',
        minSalary: '',
        maxSalary: '',
        fixedSalary: '',
        langauge: '',
        languageProficiency: '',
        samelanguage: ''
    })

    const [salary, setSalary] = useState({ salaryType: '', fixedSalary: '', minSalary: '', maxSalary: '' })
    const [skill, setSkill] = useState([]);
    const [skillInput, setSkillInput] = useState('')

    const formattedEducationList = [
        // { value: "", label: "Select Education Type", disabled: true, selected: true },
        { value: "Uneducated", label: "Uneducated" },
        { value: "10th or below 10th", label: "10th or below 10th" },
        { value: "12th pass", label: "12th Pass" },
        { value: "ITI", label: "ITI" },
        { value: "Diploma", label: "Diploma" },
        { value: "Polytechnic", label: "Polytechnic" },
        { value: "Graduation", label: "Graduation" },
        { value: "Post Graduate", label: "Post Graduate" },
    ];
    const formattedWorkPlace = [
        // { value: "", label: "Select Work Places", disabled: true, selected: true },
        { value: "On-site", label: "On-site" },
        { value: "Off-site", label: "Off-site" },
        { value: "Hybrid", label: "Hybrid" }
    ];
    const formattedShiftType = [
        // { value: "", label: "Select Shift Type", disabled: true, selected: true },
        { value: "Day Shift", label: "Day Shift" },
        { value: "Night Shift", label: "Night Shift" }
    ];

    const formattedEmploymentType = [
        // { value: "", label: "Select Employment Type", disabled: true, selected: true },
        { value: "Full Time", label: "Full Time" },
        { value: "Part Time", label: "Part Time" },
        { value: "Contract", label: "Contract" }
    ];

    const formattedExperience = [
        // { value: "", label: "Select Experience", disabled: true, selected: true },
        { value: "Fresher", label: "Fresher" },
        { value: "1-2 years", label: "1-2 years" },
        { value: "2-3 years", label: "2-3 years" },
        { value: "3-5 Years", label: "3-5 years" },
        { value: "5+ years", label: "5+ years" }
    ];
    const formattedAgeRange = [
        // { value: "", label: "Specific Age Range", disabled: true, selected: true },
        { value: "18-25", label: "18-25" },
        { value: "25-30", label: "25-30" },
        { value: "30-45", label: "30-45" },
        { value: "45+", label: "45+" }
    ];
    const formattedGender = [
        // { value: "", label: "Select Genders", disabled: true, selected: true },
        { value: "male", label: "Male" },
        { value: "female", label: "Female" },
        { value: "other", label: "Other" }
    ];

    const formattedLanguagesOptions = [
        { value: "Hindi", label: "Hindi" },
        { value: "Urdu", label: "Urdu" },
        { value: "English", label: "English" },
        { value: "Bengali", label: "Bengali" },
        { value: "Telugu", label: "Telugu" },
        { value: "Marathi", label: "Marathi" },
        { value: "Tamil", label: "Tamil" },
        { value: "Gujarati", label: "Gujarati" },
        { value: "Kannada", label: "Kannada" },
        { value: "Malayalam", label: "Malayalam" },
        { value: "Odia", label: "Odia" },
        { value: "Punjabi", label: "Punjabi" },
        { value: "Assamese", label: "Assamese" },
        { value: "Maithili", label: "Maithili" },
        { value: "Bhili/Bhilodi", label: "Bhili/Bhilodi" },
        { value: "Santali", label: "Santali" },
        { value: "Kashmiri", label: "Kashmiri" },
        { value: "Nepali", label: "Nepali" },
        { value: "Gondi", label: "Gondi" },
        { value: "Sindhi", label: "Sindhi" },
        { value: "Konkani", label: "Konkani" },
        { value: "Dogri", label: "Dogri" },
        { value: "Manipuri", label: "Manipuri" },
        { value: "Bodo", label: "Bodo" },
        { value: "Khasi", label: "Khasi" },
        { value: "Mizo", label: "Mizo" },
        { value: "Sanskrit", label: "Sanskrit" },
        { value: "Tulu", label: "Tulu" },
        { value: "Marwari", label: "Marwari" },
        { value: "Magahi", label: "Magahi" },
        { value: "Chhattisgarhi", label: "Chhattisgarhi" },
        { value: "Rajasthani", label: "Rajasthani" },
        { value: "Ho", label: "Ho" },
        { value: "Brahui", label: "Brahui" },
        { value: "Khandeshi", label: "Khandeshi" },
        { value: "Kurukh", label: "Kurukh" },
        { value: "Kodava", label: "Kodava" },
        { value: "Toda", label: "Toda" },
        { value: "Sourashtra", label: "Sourashtra" },
        { value: "Lepcha", label: "Lepcha" },
        { value: "Bhutia", label: "Bhutia" },
        { value: "Nicobarese", label: "Nicobarese" },
        { value: "Andamanese", label: "Andamanese" }
    ];

    const formattedProficiency = [
        // { value: "", label: "Select Proficiency" },
        { value: "Basic", label: "Basic" },
        { value: "Intermediate", label: "Intermediate" },
        { value: "Advance", label: "Advance" }
    ];

    const handleAddLangInArr = (e, type, idx) => {
        setMoreLanguageOption(moreLanguageOption =>
            moreLanguageOption.map((data, index) => {
                if (index === idx) {
                    if (type === 'language') {
                        return {
                            ...data,
                            languageFromMenu: e.value
                        };
                    } else if (type === 'proficiency') {
                        return {
                            ...data,
                            proficiency: e.value
                        };
                    }
                }
                return data;
            })
        );
        setError({ ...error, langauge: '', languageProficiency: '' });
        setError({ samelanguage: '', errorStatus: false });

    }

    const handleRemoveLagOpt = (index) => {
        setMoreLanguageOption(prevOptions => prevOptions.filter((_, id) => id !== index));
    };

    const handleAddMoreLagOpt = () => {
        if (moreLanguageOption?.length < 3) {
            setMoreLanguageOption(prev => [...prev, { languageFromMenu: '', proficiency: '' }]);
        }
        setError({ errorMessage: '', errorStatus: false });
    };

    const handleChangeSalary = (e, field) => {
        let newSalary = { ...salary, [field]: e.target.value };
        setError({ ...error, errorStatus: false, [field]: '' })

        if (newSalary.salaryType === "Fixed Salary") {
            newSalary = { ...newSalary, minSalary: '', maxSalary: '' };
        } else if (newSalary.salaryType === "Salary Range") {
            newSalary = { ...newSalary, fixedSalary: '' };
        } else if (newSalary.salaryType === "Not Disclose") {
            newSalary = { ...newSalary, fixedSalary: '', minSalary: '', maxSalary: '' };
        }
        setSalary(newSalary);

    };

    const handleInputChange = (e, field) => {
        setJobPostDetails({ ...jobPostDetails, [field]: e?.target?.value })
        setError({ ...error, errorStatus: '', [field]: '' })
    }
    const handleInputChangeOpenings = (e, field) => {
        const regex = new RegExp(/^[0-9]+$/)
        if (regex?.test(e?.target?.value) || e?.target?.value === '') {
            setJobPostDetails({ ...jobPostDetails, [field]: e?.target?.value })
            setError({ ...error, errorStatus: '', [field]: '' })
        }
    }

    const handleSelectChange = (e, field) => {
        setJobPostDetails({ ...jobPostDetails, [field]: e.value })
        setError({ ...error, errorStatus: '', [field]: '' })
    }

    const hadleTextEditorChange = (e, field) => {
        setJobPostDetails({ ...jobPostDetails, ['jobDescription']: e.htmlValue })
        setError({ ...error, errorStatus: '', [field]: '' })
    }

    const handleSkilSubmit = (e) => {
        e.preventDefault();
        if (!skill.includes(skillInput) && skillInput) {
            setSkill([...skill, skillInput]);
        }
        setSkillInput('');
        setError({ ...error, skill: '' })
    }
    const handleRemoveSkill = (index) => {
        setSkill(prevSkills => prevSkills.filter((_, i) => i !== index))
    }
    const handleSkillInputChange = (e) => {
        const selectedSkill = e.value;
        if (!skill.includes(selectedSkill)) {
            setSkill([...skill, selectedSkill]);
        }
        setSkillInput(selectedSkill);
        setError({ ...error, errorStatus: false, skill: '' })
    }

    const checkDuplicatesLang = () => {
        let languageArr = [];
        const duplicates = [];
        const uniqueElements = new Set();
        moreLanguageOption?.map((data, idx) => {
            languageArr.push(data?.languageFromMenu)
        })

        languageArr.forEach(element => {
            if (uniqueElements.has(element)) {
                duplicates.push(element);
            } else {
                uniqueElements.add(element);
            }
        });

        if (duplicates?.length !== 0) {
            setError({ samelanguage: 'Duplicate language names are not allowed. Please ensure each language is unique.', errorStatus: true });
            return false
        } else {
            return true
        }
    }

    const inputValidation = () => {
        if (!jobPostDetails?.jobTitle) {
            setError({ ...error, errorStatus: true, jobTitle: 'Job title is required.' })
            return true;
        }
        if (!jobPostDetails?.employmentType) {
            setError({ ...error, errorStatus: true, employmentType: 'Employment type is required.' })
            return true;
        }
        if (!jobPostDetails?.jobDescription) {
            setError({ ...error, errorStatus: true, jobDescription: 'Job description is required.' })
            return true
        }
        if (!salary.salaryType) {
            setError({ ...error, errorStatus: true, salaryType: 'Salary type is required.' })
            return true;
        }
        if (salary?.salaryType === 'Fixed Salary' && !salary.fixedSalary) {
            setError({ ...error, errorStatus: true, fixedSalary: 'Fixed salary is required.' })
            return true;
        }
        if (salary?.salaryType === 'Salary Range' && !salary.minSalary) {
            setError({ ...error, errorStatus: true, minSalary: 'Minimum salary is required.' })
            return true;
        }
        if (salary?.salaryType === 'Salary Range' && !salary.maxSalary) {
            setError({ ...error, errorStatus: true, maxSalary: 'Maximum salary is required.' })
            return true;
        }
        if (!jobPostDetails?.jobSummary) {
            setError({ ...error, errorStatus: true, jobSummary: 'Job Summary is required.' })
            return true;
        }
        if (jobPostDetails?.jobSummary?.split(' ')?.length - 1 < 100) {
            setError({ ...error, errorStatus: true, jobSummary: 'Job Summary must be greater then 100 words.' })
            return true;
        }
        if (!jobPostDetails?.shift) {
            setError({ ...error, errorStatus: true, shift: 'Shift is required.' })
            return true
        }
        if (!jobPostDetails?.workPlace) {
            setError({ ...error, errorStatus: true, workPlace: 'Work Place is required.' })
            return true;
        }
        if (!jobPostDetails?.jobLocation) {
            setError({ ...error, errorStatus: true, jobLocation: 'Job location is required.' })
            return true;
        }
        if (!jobPostDetails?.education) {
            setError({ ...error, errorStatus: true, education: 'Education is required.' })
            return true;
        }
        if (!jobPostDetails?.experience) {
            setError({ ...error, errorStatus: true, experience: 'Experience is required.' })
            return true;
        }
        if (skill.length === 0) {
            setError({ ...error, errorStatus: true, skill: 'Skill is required.' })
            return true;
        }
        if (!moreLanguageOption.some(opt => opt.languageFromMenu)) {
            setError({ ...error, errorStatus: true, langauge: 'Language is required' })
            return true;
        }
        if (!moreLanguageOption.some(opt => opt.proficiency)) {
            setError({ ...error, errorStatus: true, languageProficiency: 'Proficiency is required' })
            return true;
        }
        if (!jobPostDetails?.noOfOpening) {
            setError({ ...error, errorStatus: true, noOfOpening: 'Number of openning is required.' })
            return true;
        }
        if (moreLanguageOption.length === 0) {
            setError({ ...error, errorStatus: true, language: 'Language is required.' })
            return true;
        }
        return false
    }

    const getJobList = () => {
        axios.get(`${COMPANY_URLS?.getJoblist}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })
            .then((res) => {
                const jobsResList = res?.data?.getList || [];
                const formattedJobList = jobsResList.map(job => ({ value: job?.name, label: job?.name }));
                setAllJobList(formattedJobList)
                console.log(res)
            })
            .catch((err) => console.log(err))
    }

    const getSkillLilst = () => {
        axios.get(`${COMPANY_URLS.getSkillslist}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })
            .then((res) => {
                const skillResList = res?.data?.getList || [];
                const formattedSkillList = skillResList.map(skill => ({ value: skill?.name, label: skill?.name }));
                setRequiredSkill(formattedSkillList)
            })
            .catch((err) => console.log(err))
    }

    const createJobPost = () => {
        const data = {
            "status": "open",
            "title": jobPostDetails?.jobTitle,
            "jobDescription": jobPostDetails?.jobDescription,
            "jobDeparment": "Engineering",
            "deparmentType": jobPostDetails?.employmentType,
            "salaryType": salary?.salaryType,
            "jobSummary": jobPostDetails?.jobSummary,
            "shift": jobPostDetails?.shift,
            "workPlace": jobPostDetails?.workPlace,
            "educationRequiement": jobPostDetails?.education,
            "experience": jobPostDetails?.experience,
            "skills": skill,
            "languages": moreLanguageOption,
            "noOfOpening": jobPostDetails?.noOfOpening,
            "jobLocations":jobPostDetails?.jobLocation,
        }
        if (salary?.salaryType === 'Fixed Salary') {
            data.salaryType = salary?.salaryType;
            data.FixedSalary = salary?.fixedSalary;
        } else if (salary?.salaryType === 'Salary Range') {
            data.salaryType = salary?.salaryType;
            data.minSalary = salary?.minSalary;
            data.maxSalary = salary?.maxSalary;
        } else if (salary?.salaryType === 'Not Disclose') {
            data.salaryType = 'Not Disclose';
        }
        if (jobPostDetails?.gender) {
            data.gender = jobPostDetails?.gender
        }
        if (jobPostDetails?.ageRange) {
            data.age = jobPostDetails?.ageRange
        }


        axios.post(`${COMPANY_URLS.create_job_post}`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                successPopup(res?.data?.message)
                setJobPostDetails({ jobTitle: '', employmentType: '', jobDescription: '', shift: '', workPlace: '', education: '', experience: '', noOfOpening: '', gender: '', ageRange: '' })
                setSkill([])
                setMoreLanguageOption([{ languageFromMenu: '', proficiency: '' }])
                setNeedTorender(prev => !prev)
                navigate('/company/job-post')
            })
            .catch((err) => {
                errorPopup(err?.response?.data?.message)
            })
    }


    const handleFormSubmit = (e) => {
        e.preventDefault();
        let checkValidation = inputValidation();
        if (!inputValidation() && checkDuplicatesLang()) {
            createJobPost()
        }
    }

    // console.log('text::',checkDuplicatesLang())
    console.log('errorMsg::', error)
    useEffect(() => {
        getSkillLilst();
        getJobList()
    }, [])

    console.log('jobPostDetails', jobPostDetails)
    console.log('xxxxxxxxxx', error?.samelanguage)
    return (
        <>
            <div className="jobpost-container-main">
                <div className='back-button mb-3'><MdKeyboardBackspace onClick={() => navigate(-1)} fontSize={24} /> <span onClick={() => navigate(-1)} fontSize={24}>Create Job Post</span></div>
                <Form onSubmit={handleFormSubmit} className='d-flex flex-column gap-3'>
                    <div className="job-details-form">
                        <h1 className='mb-3'>Job Details</h1>
                        <div className='job-post-input-field-group flex-column flex-md-row d-flex justify-content-between'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Job Title*</Form.Label>
                                {/* <Form.Control
                                    type="text"
                                    placeholder="eg. Guard, Accountant, etc"
                                    value={jobPostDetails?.jobTitle}
                                    onChange={(e) => handleInputChange(e, 'jobTitle')}
                                    isInvalid={error?.jobTitle}
                                // isValid={jobPostDetails?.jobTitle.length > 3}
                                /> */}
                                <div className='inbuild-selectOption'>
                                    <Select
                                        options={allJobList}
                                        onChange={(e) => handleSelectChange(e, 'jobTitle')}
                                        value={allJobList.find(option => option.value === jobPostDetails?.jobTitle)}
                                        label="Single select"
                                    />
                                </div>
                                {error.jobTitle && <p className='text-danger'>{error.jobTitle}</p>}
                                <Form.Control.Feedback type="invalid"> {error.jobTitle} </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Employment Type*</Form.Label>
                                <div className='inbuild-selectOption'>
                                    <Select
                                        placeholder='Select Employment Type'
                                        options={formattedEmploymentType}
                                        onChange={(e) => handleSelectChange(e, 'employmentType')}
                                        value={formattedEmploymentType.find(option => option.value === jobPostDetails?.employmentType)}
                                        label="Single select"
                                    />
                                </div>
                                {error.employmentType && <p className='text-danger'>{error.employmentType}</p>}
                                <Form.Control.Feedback type="invalid">
                                    {error.employmentType}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className='job-post-input-field-group flex-column flex-md-row d-flex justify-content-between'>
                            <Form.Group className="mb-3 textEditorCreateJob" controlId="exampleForm.ControlInput1">
                                <Form.Label>Job Description*</Form.Label>
                                <Editor
                                    className='create-job-text-editor'
                                    value={jobPostDetails?.jobDescription}
                                    onTextChange={(e) => hadleTextEditorChange(e, 'jobDescription')}
                                    // onChange={(e) => handleInputChange(e, 'jobDescription')}
                                    style={{ height: '370px' }}
                                />
                                {/* <Editor value={text} onTextChange={(e) => setText(e.htmlValue)} style={{ height: '320px' }} /> */}

                                {error.jobDescription && <p className='text-danger'>{error.jobDescription}</p>}
                            </Form.Group>
                            <div>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Salary*</Form.Label>
                                    <div className='d-flex align-items-center flex-wrap gap-1 gap-md-3'>
                                        <div className='d-flex align-items-center gap-1'>
                                            <input
                                                type="radio"
                                                name="salary"
                                                checked={salary.salaryType === 'Fixed Salary'}
                                                value={'Fixed Salary'}
                                                onChange={(e) => handleChangeSalary(e, 'salaryType')}
                                                id="Fixed Salary" />
                                            <label htmlFor="Fixed Salary">Fixed Salary</label>
                                        </div>
                                        <div className='d-flex align-items-center gap-1'>
                                            <input type="radio"
                                                name="salary"
                                                checked={salary.salaryType === 'Salary Range'}
                                                value={'Salary Range'}
                                                onChange={(e) => handleChangeSalary(e, 'salaryType')}
                                                id="Salary Range" />
                                            <label htmlFor="Salary Range">Salary Range</label>
                                        </div>
                                        <div className='d-flex align-items-center gap-1'>
                                            <input
                                                type="radio"
                                                name="salary"
                                                checked={salary.salaryType === 'Not Disclose'}
                                                value={'Not Disclose'}
                                                onChange={(e) => handleChangeSalary(e, 'salaryType')}
                                                id="Not Disclose" />
                                            <label htmlFor="Not Disclose">Not Disclose</label>
                                        </div>
                                    </div>
                                    {error.salaryType && <p className='text-danger'>{error.salaryType}</p>}
                                </Form.Group>
                                {
                                    salary?.salaryType === 'Fixed Salary' &&
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Enter Fixed Salary</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Fixed Salary 3LPA"
                                            value={salary?.fixedSalary}
                                            onChange={(e) => handleChangeSalary(e, 'fixedSalary')}
                                            isInvalid={error.fixedSalary}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {error.fixedSalary}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                }
                                {
                                    salary?.salaryType === 'Salary Range' &&
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Enter variable Salary*</Form.Label>
                                        <div className='d-flex gap-4'>
                                            <div className='salary-range-inputgrp'>
                                                <div>Min*</div>
                                                <Form.Control
                                                    type="text"
                                                    value={salary?.minSalary}
                                                    onChange={(e) => handleChangeSalary(e, 'minSalary')}
                                                    placeholder="₹ 10,000"
                                                    isInvalid={error?.minSalary}
                                                />
                                            </div>
                                            <div className='salary-range-inputgrp'>
                                                <div>Max*</div>
                                                <Form.Control
                                                    value={salary?.maxSalary}
                                                    onChange={(e) => handleChangeSalary(e, 'maxSalary')}
                                                    type="text"
                                                    placeholder="₹ 15,000"
                                                    isInvalid={error?.maxSalary}
                                                />
                                            </div>
                                        </div>
                                        <Form.Control.Feedback type="invalid"> {error.minSalary} </Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid"> {error.maxSalary} </Form.Control.Feedback>
                                    </Form.Group>
                                }
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Job Summary*</Form.Label>
                                    <Form.Control
                                        // type="text"
                                        as="textarea"
                                        placeholder="add job summary"
                                        value={jobPostDetails?.jobSummary}
                                        onChange={(e) => handleInputChange(e, 'jobSummary')}
                                        isInvalid={error?.jobSummary}
                                        rows={4}
                                    // isValid={jobPostDetails?.jobTitle.length > 3}
                                    />
                                    {
                                        (jobPostDetails?.jobSummary?.split(' ')?.length - 1 <= 100 && jobPostDetails?.jobSummary?.split(' ')?.length - 1 > 0) &&
                                        <p className='text-danger text-end m-0'>
                                            Minimum words required - 100{' '} ({' '}
                                            {jobPostDetails?.jobSummary?.split(' ')?.length - 1} / 100 )</p>
                                    }
                                    <Form.Control.Feedback type="invalid">
                                        {error.jobSummary}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Shift*</Form.Label>
                                    <div className='inbuild-selectOption'>
                                        <Select
                                            placeholder='Select Shift Type'
                                            options={formattedShiftType}
                                            onChange={(e) => handleSelectChange(e, 'shift')}
                                            value={formattedShiftType.find(option => option.value === jobPostDetails?.shift)}
                                            label="Single select"
                                        />
                                    </div>
                                    {error.shift && <p className='text-danger'>{error.shift}</p>}
                                    <Form.Control.Feedback type="invalid"> {error.shift} </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Workplace*</Form.Label>
                                    <div className='inbuild-selectOption'>
                                        <Select
                                            placeholder='Select Work Places'
                                            options={formattedWorkPlace}
                                            onChange={(e) => handleSelectChange(e, 'workPlace')}
                                            value={formattedWorkPlace.find(option => option.value === jobPostDetails?.workPlace)}
                                            label="Single select"
                                        />
                                    </div>
                                    {error.workPlace && <p className='text-danger'>{error.workPlace}</p>}
                                    <Form.Control.Feedback type="invalid"> {error.workPlace} </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    {/* <Form.Label>Job Location*</Form.Label> */}
                                    {/* <div className='inbuild-selectOption'>
                                        <Select
                                            placeholder='Select Work Places'
                                            options={formattedWorkPlace}
                                            onChange={(e) => handleSelectChange(e, 'jobLocation')}
                                            value={formattedWorkPlace.find(option => option.value === jobPostDetails?.workPlace)}
                                            label="Single select"
                                        />
                                    </div> */}
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Job Location*</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className='noOfOpening'
                                            placeholder="Enter job location city."
                                            value={jobPostDetails?.jobLocation}
                                            onChange={(e) => handleInputChange(e, 'jobLocation')}
                                            isInvalid={error?.jobLocation}
                                        />
                                        <Form.Control.Feedback type="invalid"> {error.jobLocation} </Form.Control.Feedback>
                                    </Form.Group>
                                    {error.workPlace && <p className='text-danger'>{error.workPlace}</p>}
                                    <Form.Control.Feedback type="invalid"> {error.workPlace} </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>

                    </div>
                    <div className="job-details-form">
                        <h1>Candidate’s Requirements</h1>
                        <div className='job-post-input-field-group flex-column flex-md-row d-flex justify-content-between'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Education*</Form.Label>
                                <div className='inbuild-selectOption'>
                                    <Select
                                        options={formattedEducationList}
                                        onChange={(e) => handleSelectChange(e, 'education')}
                                        value={formattedEducationList.find(option => option.value === jobPostDetails?.education)}
                                        label="Single select"
                                    />
                                </div>
                                {error.education && <p className='text-danger'>{error.education}</p>}
                                <Form.Control.Feedback type="invalid"> {error.education} </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Experience*</Form.Label>
                                <div className='inbuild-selectOption'>
                                    <Select
                                        placeholder='Select Experience'
                                        options={formattedExperience}
                                        onChange={(e) => handleSelectChange(e, 'experience')}
                                        value={formattedExperience.find(option => option.value === jobPostDetails?.experience)}
                                        label="Single select"
                                    />
                                </div>
                                {error.experience && <p className='text-danger'>{error.experience}</p>}
                                <Form.Control.Feedback type="invalid"> {error.experience} </Form.Control.Feedback>
                            </Form.Group>

                        </div>
                        <div className='job-post-input-field-group flex-column flex-md-row d-flex justify-content-between'>
                            {/* skill */}
                            <div className='add-skill-box-jobPost mb-3'>
                                <Form onSubmit={handleSkilSubmit}>
                                    <Form.Group className="mb-3 w-100" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Add Required Skills*</Form.Label>
                                        <div className='inbuild-selectOption'>
                                            <Select
                                                options={requiredSkill}
                                                onChange={(e) => handleSkillInputChange(e)}
                                                value={requiredSkill.find(option => option.value === skillInput)}
                                                label="Single select"
                                            />
                                        </div>
                                    </Form.Group>
                                </Form>
                                <div className='addedSkill-conatiner'>
                                    <p>Your Selected Skills</p>
                                    <div className='added-skill-box d-flex align-items-center gap-2 flex-wrap'>
                                        {
                                            skill?.map((data, index) => (
                                                <div className='d-flex align-items-center gap-1' key={index}>
                                                    {data}
                                                    <IoCloseOutline onClick={() => handleRemoveSkill(index)} fontSize={20} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                {error?.skill && <p className='m-0 text-danger'> {error?.skill}</p>}
                            </div>
                            {/* Add Language */}
                            <div>
                                <div className='mb-3'>
                                    {
                                        moreLanguageOption.map((data, index) => (
                                            <div key={index} className='lang_child_size d-flex w-100 flex-wrap mb-3 align-items-end justify-content-start gap-3'>
                                                <div className='flex-grow-1 d-flex flex-column'>
                                                    <label htmlFor="languageSelect" className={`mb-2 ${index > 0 && 'mt-2'}`}>Language*</label>
                                                    <div className={`inbuild-selectOption`}>
                                                        <Select
                                                            options={formattedLanguagesOptions}
                                                            placeholder='Select...'
                                                            onChange={(e) => handleAddLangInArr(e, 'language', index)}
                                                            value={formattedLanguagesOptions.find(option => option.value === data?.languageFromMenu)}
                                                            label="Single select"
                                                            className='w-100'
                                                        />
                                                    </div>
                                                </div>
                                                <div className='flex-grow-1 d-flex flex-column'>
                                                    <label htmlFor="proficiencyLevel" className={`mb-2 ${index > 0 && 'mt-2'}`}>Language’s Proficiency*</label>
                                                    <div className={`inbuild-selectOption`}>
                                                        <Select
                                                            options={formattedProficiency}
                                                            placeholder='Select...'
                                                            onChange={(e) => handleAddLangInArr(e, 'proficiency', index)}
                                                            value={formattedProficiency.find(option => option.value === data?.proficiency)}
                                                            label="Single select"
                                                        />
                                                    </div>
                                                </div>
                                                <div className={`d-flex align-items-center remove-optionIcon h-100 ${moreLanguageOption?.length === 1 ? 'd-none' : index === 0 && 'opacity-0'}`}>
                                                    <IoMdClose onClick={() => handleRemoveLagOpt(index)} className='' fontSize={16} />
                                                </div>
                                                {error?.langauge && <p className='m-0 text-danger w-100'>{error?.langauge}</p>}
                                                {error?.languageProficiency && <p className='m-0 text-danger w-100'>{error?.languageProficiency}</p>}
                                            </div>
                                        ))
                                    }
                                    {error?.samelanguage && <p className='m-0 text-danger w-100'>{error?.samelanguage}</p>}
                                    <div className={`addMoreLang mt-3 ${moreLanguageOption?.length === 3 && 'd-none'}`} onClick={handleAddMoreLagOpt}>
                                        <span>Add more language</span>
                                        <FaPlus fontSize={12} />
                                    </div>
                                </div>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Number of Openings*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className='noOfOpening'
                                        placeholder="Type the number of opening"
                                        value={jobPostDetails?.noOfOpening}
                                        onChange={(e) => handleInputChangeOpenings(e, 'noOfOpening')}
                                        isInvalid={error?.noOfOpening}
                                    />
                                    <Form.Control.Feedback type="invalid"> {error.noOfOpening} </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Additional Requirements (Optional) </Form.Label>
                                    <div className='d-flex align-items-center justify-content-between gap-3'>
                                        <div className='inbuild-selectOption optionalSelectOption'>
                                            <Select
                                                placeholder='Specific age range'
                                                options={formattedAgeRange}
                                                onChange={(e) => handleSelectChange(e, 'ageRange')}
                                                value={formattedAgeRange.find(option => option.value === jobPostDetails?.ageRange)}
                                                label="Single select"
                                            // menuPlacement="auto"
                                            />
                                        </div>
                                        <div className='inbuild-selectOption optionalSelectOption'>
                                            <Select
                                                placeholder='Select gender'
                                                options={formattedGender}
                                                onChange={(e) => handleSelectChange(e, 'gender')}
                                                value={formattedGender.find(option => option.value === jobPostDetails?.gender)}
                                                label="Single select"
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </Form>
                <EventButton onClick={handleFormSubmit} className='mb-4 mt-5 job-post-publish'>Preview Post</EventButton>
            </div>
        </>
    )
}
