let AllEnvApiUrls = {
  production: {
    baseUrl: process.env.REACT_APP_PROD_COMPANY_API_URL,
  },
  staging: {
    baseUrl: process.env.REACT_APP_STAG_COMPANY_API_URL,
  },
  development: {
    baseUrl: process.env.REACT_APP_DEV_COMPANY_API_URL,
  },
};

const ApiUrls = AllEnvApiUrls[process.env.REACT_APP_ENVIRONMENT];
const CompanyAuthUrl = `${ApiUrls?.baseUrl}/company-service/v1`;

export const COMPANY_URLS = {
  // Auth API's
  login: `${CompanyAuthUrl}/auth/login`,
  register: `${CompanyAuthUrl}/auth/register`,
  forgetPassword: `${CompanyAuthUrl}/auth/forget-password`,
  logout: `${CompanyAuthUrl}/auth/logout`,

  // Company profile API's
  companyDetail: `${CompanyAuthUrl}/company/companyDetail`,
  get_profile: `${CompanyAuthUrl}/company/profile`,
  create_profile: `${CompanyAuthUrl}/company/profile`,
  update_profile: `${CompanyAuthUrl}/company/profile`,
  companyImage: `${CompanyAuthUrl}/company/companyImage`,
  getIndustryType: `${CompanyAuthUrl}/company/getIndustryType`,

  // Job-post API
  create_job_post: `${CompanyAuthUrl}/company/job-post`,
  getAllJobs: `${CompanyAuthUrl}/company/getAllJobs`,
  jobClosed: `${CompanyAuthUrl}/company/jobClosed`,
  getSingleJob: `${CompanyAuthUrl}/company/getSingleJob`,
  getJoblist: `${CompanyAuthUrl}/company/getJoblist`,
  getSkillslist: `${CompanyAuthUrl}/company/getSkillslist`,
  candidatesAppliedStatus: `${CompanyAuthUrl}/company/candidatesAppliedStatus`,
  download_resume: `${CompanyAuthUrl}/company/download-resume`
};
