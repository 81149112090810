import React from 'react'
import JobDetails from './JobDetails'
import AppliedCandidatesList from './AppliedCandidatesList'
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import './PostedJobDetails.css'

export default function PostedJobDetails() {
    const navigate = useNavigate()
    return (
        <>
            <div className="postedJobDetails_main">
                <h1 className='back-naviagate-arrow-grp'><FaArrowLeftLong onClick={() => navigate(-1)} /> <span onClick={() => navigate(-1)}>Posted Job Details</span></h1>
                <div className='d-flex flex-column flex-md-row justify-content-between align-items-start gap-3'>
                    <div className='job-details-wrapper'>
                        <h1>Job Details</h1>
                        <JobDetails />
                    </div>
                    <div className='applied-candidates-wrapper'>
                        <AppliedCandidatesList />
                    </div>
                </div>
            </div>
        </>
    )
}
