import React, { useContext, useEffect } from 'react'
import { RiMenuFold2Line, RiMenuUnfold2Line } from "react-icons/ri";
import { HiCreditCard, HiCurrencyDollar } from "react-icons/hi2";

import './Sidebar.css'
import { Nav } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PiBagSimpleFill } from 'react-icons/pi';
import { BsPeopleFill } from 'react-icons/bs';
import { IoIosSettings, IoMdHelpCircle } from "react-icons/io";
import CompanyContext from '../ContextAPI/CompanyContext';
import { COMPANY_URLS } from '../../../Utils/Company_App_Urls';
import { errorPopup, successPopup } from '../../../Utils/ToasterMessage';
import { TbLogout2 } from "react-icons/tb";
import { FiPlus } from "react-icons/fi";
import axios from 'axios';


export default function Sidebar() {
    const { setLoader, isCompanyProfileComplate } = useContext(CompanyContext);
    const navigate = useNavigate()
    const { toggleSideBar, setToggleSideBar } = useContext(CompanyContext)
    const location = useLocation();

    useEffect(()=>{
        if(window?.innerWidth <= 800) setToggleSideBar(true);
        else setToggleSideBar(false);
    },[])
    const navItemsObj = [
        {
            name: 'Create Post',
            pathUrl: 'create-job',
            image: <FiPlus fontSize={25} />
        },
        {
            name: 'Job Post',
            pathUrl: 'job-post',
            image: <PiBagSimpleFill fontSize={25} />
        },
        {
            name: 'Candidates',
            pathUrl: 'candidates',
            image: <BsPeopleFill fontSize={25} />
        },
        {
            name: 'Subscription Plan',
            pathUrl: 'subscription-Plan',
            image: <HiCreditCard fontSize={25} />
            // image: <img src={subscriptionIcon} alt="subscription Icon" />
        },
        {
            name: 'Billing & Invoices',
            pathUrl: 'billing-invoices',
            image: <HiCurrencyDollar fontSize={28} />
        },
        {
            name: 'Setting',
            pathUrl: 'setting',
            image: <IoIosSettings fontSize={30} />
        },
        {
            name: 'Help & Support',
            pathUrl: 'help-support',
            image: <IoMdHelpCircle fontSize={27} />
        },
    ]

    const checkLocation = () => {
        const locationArr = location.pathname.split('/');
        return locationArr || '';
    };

    const handleCloseSideBarNavigate = (path) => {
        navigate(path)
    }

    const handleToggleSidebar = () => {
        setToggleSideBar(prev => !prev)
    }

    const handleLogOut = () => {
        setLoader(true);
        axios.post(`${COMPANY_URLS.logout}`, {
            "refreshToken": `${localStorage.getItem('acessToken')}`
        })
            .then(res => {
                successPopup(res?.data?.message);
                localStorage.removeItem('token');
                localStorage.removeItem('companyRole');
                localStorage.removeItem('isprofileComplete');
                localStorage.removeItem('registeredEmail');
                setLoader(false);
                navigate('/company/login');
            })
            .catch((err) => {
                errorPopup(err?.response?.data?.message);
                setLoader(false);
            });
    };

    return (
        <>
            <div className={`open_sidebar_btn ${!toggleSideBar ? 'd-none' : 'd-block'}`}>
                    <RiMenuFold2Line className='closed_nav_icon my-4' fontSize={30} onClick={handleToggleSidebar} />
                <div className={`${!isCompanyProfileComplate && 'disabled-navItems'} nav_close_items_group d-flex flex-column`}>
                    <FiPlus onClick={() => handleCloseSideBarNavigate('/company/create-job')} fontSize={25} />
                    <PiBagSimpleFill onClick={() => handleCloseSideBarNavigate('/company/job-post')} fontSize={25} />
                    <BsPeopleFill onClick={() => handleCloseSideBarNavigate('/company/candidates')} fontSize={25} />
                    <HiCreditCard fontSize={25} onClick={() => handleCloseSideBarNavigate('/company/subscription-Plan')} />
                    <HiCurrencyDollar fontSize={28} onClick={() => handleCloseSideBarNavigate('/company/subscription-Plan')} />
                    <IoIosSettings fontSize={30} onClick={() => handleCloseSideBarNavigate('/company/billing-invoices')} />
                    <IoMdHelpCircle fontSize={27} onClick={() => handleCloseSideBarNavigate('/company/setting')} />
                    <TbLogout2 onClick={handleLogOut} className='me-2' fontSize={25} />
                </div>
            </div>

            <div className={`sidebar_main_company ${!toggleSideBar ? 'd-block' : 'd-none'}`}>
                <div className={`menu-toggle-icon`}>
                    <RiMenuUnfold2Line onClick={handleToggleSidebar} fontSize={30} />
                </div>
                <Nav className="me-auto side-nav-items d-flex flex-column">
                    {
                        navItemsObj.map((data, index) => (
                            <div key={index} className={!isCompanyProfileComplate && 'disabled-navItems'}>
                                <div className={`nav-items-left-border ${checkLocation().includes(data.pathUrl) && 'border-active'}`} />
                                <Link
                                    className={` ${checkLocation().includes(data.pathUrl) && 'active-anchore'}`}
                                    to={data?.pathUrl}>{data.image} <span>{data.name}</span>
                                </Link>
                            </div>
                        ))
                    }
                    <div className='log-out-btn d-block'><TbLogout2 className='me-2' fontSize={25} /><span>Logout</span></div>
                </Nav>
            </div>
        </>
    )
}
