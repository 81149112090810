import React, { useContext, useEffect, useState } from 'react'
import bagImage from '../../../assets/bag-solid.png'
import cashMultiple from '../../../assets/cash-multiple.png'
import { FaAngleLeft, FaAngleRight, FaLocationDot } from 'react-icons/fa6'
import { EventButton, PaginationBtn, StepperBackButton } from '../../../User/component/ReUseComps/Button'
import { useNavigate, useParams } from 'react-router-dom'
import CompanyContext from '../../Component/ContextAPI/CompanyContext'
import CandaidateProfileView from '../../Component/PopupModal/AppliedCandidateProfile/CandaidateProfileView'
import NoCandidateApplied from './NoCandidateApplied'
import suitcase from '../../../assets/solar_suitcase-outline.png'
import educationIcon from '../../../assets/fluent-mdl2_education.png'
import mage_email from '../../../assets/mage_email.png'
import weui_location from '../../../assets/weui_location-outlined.png'
import { COMPANY_URLS } from '../../../Utils/Company_App_Urls'
import dummyIcon from '../../../assets/dummy-image.jpg'
import axios from 'axios'
import './PostedJobDetails.css'

export default function AppliedCandidatesList() {
    const { appliedCandidateModalOpen, setAppliedCandidateModalOpen, appliedCandidateDetails, setAppliedCandidateDetails } = useContext(CompanyContext)
    const [paginationBtnArr, setpaginationBtnArr] = useState([1, 2, 3, 4, 5])
    const [getAllAppliedCandidates, setGetAllAppliedCandidates] = useState([]);
    const [clipArrLength, setClipArrLength] = useState({ min: 0, max: 10 })
    const jobId = useParams()
    const navigate = useNavigate()

    const handleIncPagination = () => {
        setpaginationBtnArr(paginationBtnArr.map((item, _) => item + 1))
    }

    const handleDecPagination = () => {
        if (paginationBtnArr[0] > 1) {
            setpaginationBtnArr(paginationBtnArr.map((item, _) => item - 1))
        }
    }

    const handlePaginationSize = (pgNo) => {
        let max = pgNo * 10;
        let min = max - 10 >= 0 ? max - 10 : 0;
        setClipArrLength({ min: min, max: max })
    }

    const getSingleJobDetails = async () => {
        await axios.get(`${COMPANY_URLS.getSingleJob}`, {
            params: {
                id: jobId?.id
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                const result = res?.data?.result?.[0] || [];
                setGetAllAppliedCandidates(result?.appliedUserProfile)
            })
            .catch((err) => { console.log(err) })
    }

    const employeeData = [
        {
            userImage: 'https://via.placeholder.com/150',
            userName: 'John Doe',
            jobTitle: 'Software Developer',
            experience: '1-2 Years',
            salary: '12000k - 15000k',
            skills: ['React', 'JavaScript', 'CSS'],
            location: 'Noida',
            jobDescription: 'John has worked on various front-end projects and is proficient in developing responsive web applications using React...'
        },
        {
            userImage: 'https://via.placeholder.com/150',
            userName: 'Jane Smith',
            jobTitle: 'Frontend Developer',
            experience: '2-3 Years',
            salary: '14000k - 16000k',
            skills: ['HTML', 'CSS', 'JavaScript'],
            location: 'London',
            jobDescription: 'Jane is a skilled frontend developer with a keen eye for design and user experience, having worked on multiple projects in fast-paced environments...'
        },
        {
            userImage: 'https://via.placeholder.com/150',
            userName: 'Robert Brown',
            jobTitle: 'Backend Developer',
            experience: '3-5 Years',
            salary: '15000k - 18000k',
            skills: ['Node.js', 'Express', 'MongoDB'],
            location: 'New Delhi',
            jobDescription: 'Robert specializes in backend development, building scalable APIs and managing databases for large-scale applications...'
        },
        {
            userImage: 'https://via.placeholder.com/150',
            userName: 'Emily Johnson',
            jobTitle: 'Full Stack Developer',
            experience: '4-6 Years',
            salary: '16000k - 20000k',
            skills: ['React', 'Node.js', 'SQL'],
            location: 'Jaipur',
            jobDescription: 'Emily has extensive experience as a full stack developer, working on both front-end and back-end technologies to deliver robust solutions...'
        },
        {
            userImage: 'https://via.placeholder.com/150',
            userName: 'Michael Lee',
            jobTitle: 'UI/UX Designer',
            experience: '2-4 Years',
            salary: '13000k - 15000k',
            skills: ['Figma', 'Sketch', 'Adobe XD'],
            location: 'Lucknow',
            jobDescription: 'Michael is a creative UI/UX designer with a passion for crafting visually appealing and user-friendly interfaces...'
        }
    ];

    const postedDate = (data) => {
        const firstDate = new Date(data);
        const currentDate = new Date();
        const timeDifference = currentDate - firstDate;
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
        return daysDifference >= 1 ? `${daysDifference} Day${daysDifference > 1 ? 's' : ''} ago` : 'Posted Today';
    }

    const handleToggleModalBox = (index) => {
        setAppliedCandidateModalOpen(prev => !prev)
        setAppliedCandidateDetails(getAllAppliedCandidates[index])
    }

    console.log('getAllAppliedCandidates::', getAllAppliedCandidates)
    console.log('appliedCandidateDetails::', appliedCandidateDetails)

    useEffect(() => {
        getSingleJobDetails()
    }, [])

    console.log('!getAllAppliedCandidates?.[0]?.name ',!getAllAppliedCandidates?.[0]?.name )
    return (
        <>
            <CandaidateProfileView />
            <h1 className='appliedCandidatesListTitle'>
                <p className='mb-0 fs-5 fs-md-4' >Applied Candidates <span>{getAllAppliedCandidates?.[0]?.name ? getAllAppliedCandidates?.length : 0} </span></p>
                <span onClick={()=>navigate('/company/candidates')}>View All</span>
            </h1>
         {getAllAppliedCandidates?.[0]?.name && <div className="similar-job-main d-flex flex-column gap-3">
                {getAllAppliedCandidates?.map((job, index) => (
                    <div key={index} className="similar-job-card d-flex flex-column gap-3">
                        <div className="similar-job-header d-flex align-items-start justify-content-between flex-wrap">
                            <div className='d-flex gap-2 align-items-center'>
                                <div className="compy-logo-box">
                                    <img src={job?.profile || dummyIcon} alt="Company Logo" />
                                </div>
                                <div className='compy-applied-title-descr'>
                                    <h1>{job?.name}</h1>
                                    <p>{job?.JobTitle}</p>
                                </div>
                            </div>
                            {/* <p className='m-0'>{postedDate(job?.createdAt)}</p> */}
                        </div>
                        {/* <div className="candidateDetailsCard d-flex align-items-center gap-3">
                            {job.skills.map((skill, idx) => (
                                <div key={idx}>{skill}</div>
                            ))}
                        </div> */}
                        {/* <div className="candidateDetailsCard d-flex align-items-center flex-wrap gap-3">
                            <div>{job?.experience}</div>
                            <div>{job?.education}</div>
                            {job?.languages?.map((lag, idx) => (
                                <div key={idx}>{lag?.languageFromMenu}</div>
                            ))}
                        </div> */}
                        <div className='d-flex align-items-center justify-content-between gap-2 flex-wrap'>
                            <div className='d-flex align-items-center gap-1 candiApp-qulify text-capitalize'>
                                <img src={suitcase} alt="suitcase" />
                                <span>{job?.experience}</span>
                            </div>
                            <div className='d-flex align-items-center gap-1 candiApp-qulify text-capitalize'>
                                <img src={educationIcon} alt="suitcase" />
                                <span>{job?.education}</span>
                            </div>
                            <div className='d-flex align-items-center gap-1 candiApp-qulify'>
                                <img src={mage_email} alt="suitcase" />
                                <span>{job?.email || 'exapmple@yopmail.com'}</span>
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-between text-capitalize'>
                            <div className='d-flex align-items-center gap-2 candiApp-qulify'>
                                <img src={weui_location} alt="suitcase" />
                                <span>{job?.address}</span>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center flex-wrap gap-3'>
                            <button className="out-line-button">
                                {console.log('job?.resume',job)}
                                <a className='dnkjsa' href={job?.resume} target='_blank'>Download Resume</a>
                            </button>
                            <button className='fill-bg-button' onClick={() => handleToggleModalBox(index)}>View Profile</button>
                        </div>
                    </div>
                ))}
                {getAllAppliedCandidates?.length > 0 && <div className="footer-pagination d-flex align-items-center justify-content-center pb-3">
                    {/* <button className='pagination-btn'>1</button> */}
                    <div className='d-flex align-items-center gap-2'>
                        <PaginationBtn className={`pagination-btn ${paginationBtnArr[0]==1 && 'hoverDisbledPage'}`}
                            onClick={handleDecPagination}>
                            <FaAngleLeft fontSize={20} />
                            Previous
                        </PaginationBtn>
                        {
                            paginationBtnArr?.map((data, idx) => (
                                <PaginationBtn
                                    key={idx}
                                    onClick={() => handlePaginationSize(data)}
                                    disabled={getAllAppliedCandidates?.length < (data * 10)}
                                    className={`pagination-btn ${(clipArrLength.max === data * 10 && 'pagination-activex')}
                                        ${getAllAppliedCandidates?.length < (data * 10) && idx > 0 && 'd-none'}
                                    `}
                                >
                                    {data}
                                </PaginationBtn>
                            ))
                        }
                        <PaginationBtn
                            className={`pagination-btn ${getAllAppliedCandidates?.length < (paginationBtnArr[paginationBtnArr?.length - 1] * 10) && 'hoverDisbledPage'}`}
                            onClick={handleIncPagination}
                            disabled={getAllAppliedCandidates?.length < (paginationBtnArr[paginationBtnArr?.length - 1] * 10)}
                        >
                            Next
                            <FaAngleRight fontSize={20} />
                        </PaginationBtn>
                    </div>

                </div>}
                {/* {!getAllAppliedCandidates?.[0]?.name && <NoCandidateApplied />} */}
            </div>}
            {!getAllAppliedCandidates?.[0]?.name  && <NoCandidateApplied />}
        </>
    )
}
