import React, { useContext, useEffect, useState } from 'react'
import { GoArrowRight } from "react-icons/go";
import dummyComapyLogo from '../../../../assets/dummyComapyLogo.png'
import { FaAngleLeft, FaAngleRight, FaLocationDot } from "react-icons/fa6";
import { PaginationBtn } from '../../../component/ReUseComps/Button';
import UserContext from '../../../component/ContextApi/UserContext';
import { successPopup } from '../../../../Utils/ToasterMessage';
import { useLocation, useNavigate } from 'react-router-dom';
import cashMultiple from '../../../../assets/cash-multiple.png'
import bagSolid from '../../../../assets/bag-solid.png'
import archiveIcon from '../../../../assets/material-symbols-light_archive-outline.png'
import { URLS } from '../../../../Utils/App_urls';
import { BsArchiveFill } from "react-icons/bs";

import axios from 'axios';
import './JobCards.css'
import { Spinner } from 'react-bootstrap';

export default function JobCards() {
    const { allJobAfterFilter, setAllAfterFilter } = useContext(UserContext);
    const location = useLocation();
    const navigate = useNavigate()
    const [allJobs, setAllJobs] = useState([])
    const [paginationBtnArr, setpaginationBtnArr] = useState([1, 2, 3, 4, 5]);
    const [clipArrLength, setClipArrLength] = useState({ min: 0, max: 10 })
    const [filterInputOpt, setFilterInputOpt] = useState({ jobTitle: '', jobLocation: '' });
    const [uniqueIdForSpinner, setUniqueIdForSpinner] = useState('')
    const [filterJobsArr, setFilterJobsArr] = useState([])
    const [notInterestedLoader, setNotInterestedLoader] = useState(false)


    const handleSubmitForm = (e) => {
        e.preventDefault();
    }

    const getAllJobs = () => {
        axios.get(`${URLS.getAllJob}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
        })
            .then((res) => {
                console.log('getAllJOb', res)
                // setAllJobs(res?.data?.result);
                setAllAfterFilter(res?.data?.result);
                setFilterJobsArr(res?.data?.result);
            })
            .catch((err) => { console.log('err', err) })
    }

    const redirectTojobDetails = (id) => {
        navigate(`/user/find-job/job-details/${id}`, {
            state: { DATA: id }
        })
    }
    const convertHtmlToText = (htmlString) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;
        return tempElement.textContent || tempElement.innerText || "";
    };

    const handlePaginationSize = (pgNo) => {
        let max = pgNo * 10;
        let min = max - 10 >= 0 ? max - 10 : 0;
        setClipArrLength({ min: min, max: max })
    }

    const handleIncPagination = () => {
        setpaginationBtnArr(paginationBtnArr.map((item, _) => item + 1))
        setClipArrLength({ min: (paginationBtnArr[paginationBtnArr.length - 1] * 10) - 10, max: paginationBtnArr[paginationBtnArr.length - 1] * 10 })
    }
    const handleDecPagination = () => {
        if (paginationBtnArr[0] > 1) {
            setpaginationBtnArr(paginationBtnArr.map((item, _) => item - 1))
        }
        paginationBtnArr[0] >= 0 && setClipArrLength({ min: (paginationBtnArr[0] * 10) - 10, max: paginationBtnArr[0] * 10 })
    }

    const handleFilterAppliedJob = (e, type) => {
        let enterTitle = e.target.value;
        type === 'title' && setFilterInputOpt({ ...filterInputOpt, jobTitle: enterTitle });
        type === 'location' && setFilterInputOpt({ ...filterInputOpt, jobLocation: enterTitle });
        enterTitle?.length === 0 && filterOnSearchBtn();
    }

    const filterOnSearchBtn = () => {
        let filterJobs = filterJobsArr?.filter((data) => {
            const title = data?.title?.toLowerCase();
            return title?.includes(filterInputOpt?.jobTitle?.toLowerCase())

        })

        filterJobs = filterJobs?.filter((data) => {
            const location = data?.workPlace?.toLowerCase();
            return location?.includes(filterInputOpt?.jobLocation?.toLowerCase())

        })
        setAllAfterFilter(filterJobs)
    }
    const handleNotInterestedJob = (id) => {
        setUniqueIdForSpinner(id)
        setNotInterestedLoader(true)
        axios.post(`${URLS.notInterestedJob}`, {
            JobId: id,
            status: false
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
            },
        })
            .then((res) => {
                successPopup(res?.data?.message);
                getAllJobs();
                setNotInterestedLoader(false)
            })
            .catch((err) => {
                setNotInterestedLoader(false)
                console.log(err)
            })
    }

    const postedDate = (data) => {
        const firstDate = new Date(data);
        const currentDate = new Date();
        const timeDifference = currentDate - firstDate;
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
        return daysDifference > 0 ? `${daysDifference} Day${daysDifference > 1 ? 's' : ''} ago` : 'Posted Today';
    }

    useEffect(() => {
        getAllJobs();
    }, [])
    return (
        <>
            <div className="job-card-container">
                {allJobAfterFilter?.length ?
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                        <p className='vailable-job-para mb-2'>Available <span>{allJobAfterFilter?.length}</span> Jobs</p>
                        <div
                            className='parentButtonGrp'
                            onClick={() => navigate('/user/notIntested-jobs')}>
                            <img src={archiveIcon} alt="archiveIcon" />
                            <span>Archive</span>
                        </div>

                    </div>
                    : <p className='vailable-job-para mb-3 text-center'>Job Not Found</p>}

                {/* Job cards */}
                <div>
                    {allJobAfterFilter?.map((job, index) => (
                        (index >= clipArrLength.min && index < clipArrLength.max) &&
                        <div className='job-search-body mb-3 px-1' key={index}>
                            <div className="job-cards d-flex flex-wrap">
                                <div className="job-right-container d-flex flex-column ps-0 pe-3 gap-2 flex-grow-1">
                                    <div className="job-card-header d-flex align-items-start justify-content-between flex-wrap gap-2">
                                        <div className="company-title d-flex align-items-start gap-3 mb-2">
                                            <div><img src={job?.companyProfileDetail?.[0]?.companylogo || dummyComapyLogo} alt={job?.company} /></div>
                                            <div className='compay-name'>
                                                <h1 className='m-0'>{job?.title}</h1>
                                                <p className='m-0'>{job?.companyProfileDetail[0]?.companyName || 'N/A'}</p>
                                            </div>
                                        </div>
                                        <p className='m-0 d-flex flex-column'>
                                            <span>{postedDate(job?.createdAt?.split('T')[0])}</span>
                                        </p>
                                    </div>
                                    <div className='job-conadtion-box'>
                                        <div><img src={bagSolid} alt="bag solid" /> <span>{job?.experience}</span></div>
                                        {
                                            job?.salaryType === "Fixed Salary" &&
                                            <div><img src={cashMultiple} alt="cash multiple" /> <span>{job?.FixedSalary}k</span></div>
                                        }
                                        {
                                            job?.salaryType === "Salary Range" &&
                                            <div><img src={cashMultiple} alt="cash multiple" /> <span>{job?.minSalary}k - {job?.maxSalary}k</span></div>
                                        }
                                        {
                                            job?.salaryType === "Not Disclose" &&
                                            <div><img src={cashMultiple} alt="cash multiple" /> <span>{job?.salaryType}</span></div>
                                        }
                                        <div><FaLocationDot /> <span>{job?.workPlace}</span></div>
                                    </div>

                                    <div className="job-preferences d-flex gap-1 gap-sm-3 flex-wrap">
                                        <span key={index}>{job?.deparmentType}</span>
                                        <span key={index}>{job?.workPlace}</span>
                                        <span key={index}>{job?.shift}</span>
                                        {/* {job?.preferences?.map((preference, index) => ())} */}
                                    </div>

                                    {job?.jobSummary ?
                                        <div id='formating-typo'>
                                            {job?.jobSummary?.slice(0, 400)}
                                            {job?.jobSummary?.length > 400 && '...'}
                                        </div>
                                        : <div id='formating-typo' dangerouslySetInnerHTML={{ __html: convertHtmlToText(job?.jobDescription)?.slice(0, 300) + '...' }} />
                                    }

                                    <div className="company-req-skills d-flex flex-wrap gap-2 gap-sm-3">
                                        {job?.skills?.slice(0, 3)?.map((skill, index) => (
                                            <span key={index}>{skill}</span>
                                        ))}
                                        {job?.skills?.length > 3 && <span>{job?.skills?.length - 3}+ More</span>}
                                    </div>
                                </div>
                                <div className="cards-btn-box flex-row flex-sm-column flex-wrap">
                                    <button className='job-search-btn' onClick={() => redirectTojobDetails(job?._id)}>View <GoArrowRight fontSize={20} /></button>
                                    <button className='job-search-outline-btn' onClick={() => handleNotInterestedJob(job?._id)}>
                                        {notInterestedLoader && (uniqueIdForSpinner === job?._id) && <Spinner style={{ width: '15px', height: '15px', borderWidth: '2px', marginRight: '2px' }} animation="border" />}
                                        Not Interested
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                    {allJobAfterFilter?.length > 0 && <div className="footer-pagination d-flex align-items-center flex-wrap justify-content-center gap-2 pb-3">
                        <p className='vailable-job-para page-table-typo'>Page No. {clipArrLength?.max / 10} / {Math.ceil(allJobAfterFilter?.length / 10)}</p>
                        <div className='d-flex align-items-center gap-2'>
                            <PaginationBtn style={{ width: 'fit-content' }} className='pagination-btn edgePageBtn'
                                onClick={handleDecPagination}
                                disabled={paginationBtnArr[0] === 1}>
                                <FaAngleLeft fontSize={20} />
                                Previous
                            </PaginationBtn>
                            {
                                paginationBtnArr.map((data, idx) => (
                                    <PaginationBtn
                                        className={`pagination-btn ${clipArrLength?.max / 10 === data && 'activePageBtn'}`}
                                        onClick={() => handlePaginationSize(data)}
                                        disabled={Math.ceil(data > Math.ceil(allJobAfterFilter?.length / 10))}>
                                        {data}
                                    </PaginationBtn>
                                ))
                            }
                            <PaginationBtn className={`pagination-btn ${allJobAfterFilter?.length <= 60 && 'd-none'}`} disabled>...</PaginationBtn>
                            <PaginationBtn
                                style={{ width: 'fit-content' }}
                                className='pagination-btn edgePageBtn'
                                onClick={handleIncPagination}
                                disabled={allJobAfterFilter?.length < (paginationBtnArr[paginationBtnArr?.length - 1] * 10)}
                            >
                                Next
                                <FaAngleRight fontSize={20} />
                            </PaginationBtn>
                        </div>

                    </div>}
                </div>
            </div>
        </>
    )
}
