import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap'
import Select from 'react-select';
import { FiCamera } from "react-icons/fi";
import { EventButton } from '../../../User/component/ReUseComps/Button';
import axios from 'axios';
import { COMPANY_URLS } from '../../../Utils/Company_App_Urls';
import { errorPopup, successPopup } from '../../../Utils/ToasterMessage';
import CompanyContext from '../../Component/ContextAPI/CompanyContext';
import './CreateProfile.css';
import { Oval } from 'react-loader-spinner';

const no_of_employee = [
    {
        value: '1-10',
        label: '1-10'
    },
    {
        value: '11-50',
        label: '11-50'
    },
    {
        value: '51-100',
        label: '51-100'
    },
    {
        value: '101-200',
        label: '101-200'
    },
    {
        value: '201-500',
        label: '201-500'
    },
    {
        value: '500 +',
        label: 'above 500'
    }
]

export default function CreateProfile() {
    const navigate = useNavigate()
    const { getCompanyDetails, setNeedTorender, setProfileLogo } = useContext(CompanyContext)
    const fileInputRef = useRef(null)
    const [logoName, setLogoName] = useState('')
    const [loader, setLoader] = useState(false)
    const [logoUrl, setLogoUrl] = useState('')
    const [yearList, setYearList] = useState([]);
    const [imageUrl, setImageUrl] = useState('');
    const [gstValidationError, setGstValidationError] = useState({ gstValErr: '', gstValStatus: true });
    const [industryType, setIndustryType] = useState([])
    const [companyDetails, setCompanyDetails] = useState({
        companyName: '',
        employees: '',
        industryType: '',
        companyLogo: '',
        designation: '',
        websiteUrl: '',
        gstNumber: '',
        companyAddress: '',
        companyDescription: '',
        contactNumber: '',
        email: localStorage.getItem('registeredEmail'),
        establishYear: '',
    });

    const [error, setError] = useState({
        errorStatus: true,
        errorCompanyName: '',
        errorEmployee: '',
        errorIndustryType: '',
        errorCompanyLogo: '',
        errorDesignation: '',
        errorWebsiteUrl: '',
        errorGstNumber: '',
        errorCompanyAdd: '',
        errorCompanyDescr: '',
        errorContactNumber: '',
        errorEmail: '',
        errorEstablishYear: ''
    });
    console.log('error', error)

    const handleInputChange = (e, field) => {

        setError({ ...error, errorStatus: true, errorCompanyName: '', errorEmployee: '', errorIndustryType: '', errorContactNumber: '', errorCompanyLogo: '', errorDesignation: '', errorWebsiteUrl: '', errorGstNumber: '', errorCompanyAdd: '', errorEstablishYear: '', errorCompanyDescr: '' });
        if (field === 'contactNumber') {
            const regex = new RegExp(/^[0-9]*$/)
            regex.test(e.target.value) && setCompanyDetails({ ...companyDetails, [field]: e.target.value });
        } else if (field === 'establishYear' || field === "industryType" || field === "employees") {
            setCompanyDetails({ ...companyDetails, [field]: e.value });
        } else {
            setCompanyDetails({ ...companyDetails, [field]: e.target.value });
        }
    };

    const handleGSTInputChange = (e, field) => {
        let gstNumber = e.target.value;
        setError({ ...error, errorStatus: true, errorCompanyName: '', errorEmployee: '', errorIndustryType: '', errorCompanyLogo: '', errorDesignation: '', errorWebsiteUrl: '', errorGstNumber: '', errorCompanyAdd: '', errorEstablishYear: '', errorCompanyDescr: '' });
        setCompanyDetails({ ...companyDetails, [field]: e.target.value });
        // console.log('gstNumber', validateGSTIN(gstNumber))
        if (!validateGSTIN(gstNumber)) {
            setGstValidationError({ gstValErr: 'Invalid GST number', gstValStatus: true })
        }
        if (validateGSTIN(gstNumber)) {
            setGstValidationError({ gstValErr: '', gstValStatus: false })
        }
    }

    const validateGSTIN = (gstin) => {
        const isValidFormat = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(gstin);
        return isValidFormat;
    };


    const formvalidation = () => {
        if (!companyDetails.companyName) {
            setError({ errorStatus: false, errorCompanyName: 'Company name is required.' });
            return false;
        }
        if (companyDetails.companyName?.length < 3) {
            setError({ errorStatus: false, errorCompanyName: 'Company name is must be greater than 3 letter.' });
            return false;
        }
        if (!companyDetails?.contactNumber) {
            setError({ errorStatus: false, errorContactNumber: 'Contact number is required.' });
            return false;
        }
        if (!companyDetails?.email) {
            setError({ errorStatus: false, errorEmail: 'Email is required.' });
            return false;
        }
        if (!companyDetails.employees || companyDetails?.employees === '') {
            setError({ errorStatus: false, errorEmployee: 'Number of employees is required.' });
            return false;
        }
        if (!companyDetails.industryType) {
            setError({ errorStatus: false, errorIndustryType: 'Industry type is required.' });
            return false;
        }
        if (!imageUrl) {
            setError({ errorStatus: false, errorCompanyLogo: 'Company logo is required.' });
            return false;
        }
        if (!companyDetails.designation) {
            setError({ errorStatus: false, errorDesignation: 'Owner name is required.' });
            return false;
        }
        if (!companyDetails?.establishYear) {
            setError({ errorStatus: false, errorEstablishYear: 'Establish year is required.' });
            return false;
        }
        if (!companyDetails.websiteUrl) {
            setError({ errorStatus: false, errorWebsiteUrl: 'Website URL is required.' });
            return false;
        }
        if (!companyDetails.gstNumber) {
            setError({ errorStatus: false, errorGstNumber: 'GST number is required.' });
            return false;
        }
        if (!companyDetails.companyAddress) {
            setError({ errorStatus: false, errorCompanyAdd: 'Company address is required.' });
            return false;
        }
        if (!companyDetails.companyDescription) {
            setError({ errorStatus: false, errorCompanyDescr: 'Company description is required.' });
            return false;
        }
        return true;
    };

    const imageUploadChange = (e) => {
        let file = e?.target?.files?.[0]
        setLogoName(file?.name);
        if (file) uploadImageInS3(file);
    }

    const handleImageUpload = () => {
        fileInputRef.current.click();
    }

    const handleSubmitProfile = (e) => {
        e.preventDefault();
        const isValid = formvalidation();
        if (isValid && !gstValidationError?.gstValStatus) {
            createCompanyProfile()
        } else {
        }
    };

    const uploadImageInS3 = async (file) => {
        const formData = new FormData()
        formData.append('image', file);
        setError({ ...error, errorStatus: true, errorCompanyName: '', errorEmployee: '', errorIndustryType: '', errorCompanyLogo: '', errorDesignation: '', errorWebsiteUrl: '', errorGstNumber: '', errorCompanyAdd: '', errorEstablishYear: '', errorCompanyDescr: '' });
        await axios.post(`${COMPANY_URLS.companyImage}`, formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {
                successPopup(res?.data?.message)
                setImageUrl(res?.data?.data?.imageUrl)
            })
            .catch((err) => {
                errorPopup(err?.response?.data?.message);
            })
    }

    const createCompanyProfile = async () => {
        setLoader(true)
        const data = {
            "companyName": companyDetails?.companyName,
            "ownerName": companyDetails?.designation,
            "contactNo": Number(companyDetails?.contactNumber),
            "establishYear": companyDetails?.establishYear?.toString(),
            "email": companyDetails?.email,
            "weburl": companyDetails?.websiteUrl,
            "noOfEmployees": companyDetails?.employees,
            "gstNumber": companyDetails?.gstNumber,
            "preferredIndustry": companyDetails?.industryType,
            "location": companyDetails?.companyAddress,
            "companylogo": imageUrl,
            "description": companyDetails?.companyDescription
        }

        axios.post(`${COMPANY_URLS.create_profile}`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                // successPopup(res?.data?.message);
                setLoader(false)
                getCompanyDetailsUpdated()
                getCompanyDetails()
            })
            .catch((err) => {
                setLoader(false)
                errorPopup(err?.response?.data?.message);
            })
    }

    const getCompanyDetailsUpdated = () => {
        axios(COMPANY_URLS.companyDetail, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                if (res?.data?.data?.profileComplete) {
                    localStorage.setItem('isprofileComplete', "true");
                }
                navigate('/company/job-post');
                successPopup('Profile created successfully.');
            })
            .catch((err) => {
                // setIsLogin(false);
            })
    }

    const getIndustryType = () => {
        axios.get(COMPANY_URLS?.getIndustryType, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                Accept: 'application/json'
            }
        })
            .then((res) => {
                let arr = res?.data?.IndustryList?.map((data) => {
                    return { value: data?.name, label: data?.name }
                })
                setIndustryType(arr);
            })
            .catch((err) => {
            })
    }

    const establishYearList = () => {
        let currYear = new Date().getFullYear();
        let yearListArr = [];
        for (let i = 0; i <= currYear - 1900; i++) {
            yearListArr.push({ value: currYear - i, label: currYear - i });
        }
        setYearList(yearListArr)
    }

    useEffect(() => {
        establishYearList();
        getIndustryType();
    }, [])

    return (
        <div className="create-profile-main">
            <h1 className='mb-3'>Create Company Profile</h1>
            <Form onSubmit={handleSubmitProfile} className='d-flex flex-wrap align-items-start justify-content-between gap-2 gap-md-5'>
                <div className="leftSide-form-section">
                    <Form.Group className="mb-3" controlId="formCompanyName">
                        <Form.Label>Company Name*</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter your company name"
                            value={companyDetails.companyName}
                            onChange={(e) => handleInputChange(e, 'companyName')}
                            isInvalid={error.errorCompanyName}
                        />
                        <Form.Control.Feedback type="invalid">
                            {error.errorCompanyName}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formCompanyName">
                        <Form.Label>Contact Number*</Form.Label>
                        <Form.Control
                            type="text"
                            maxLength={10}
                            minLength={10}
                            placeholder="Company Contact Number"
                            value={companyDetails.contactNumber}
                            onChange={(e) => handleInputChange(e, 'contactNumber')}
                            isInvalid={error.errorContactNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                            {error.errorContactNumber}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formCompanyName">
                        <Form.Label>Email*</Form.Label>
                        <Form.Control
                            type="email"
                            disabled
                            placeholder="Company mail"
                            value={companyDetails.email}
                            onChange={(e) => handleInputChange(e, 'email')}
                            isInvalid={error.errorEmail}
                        />
                        <Form.Control.Feedback type="invalid">
                            {error.errorEmail}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Number of Employees*</Form.Label>
                        {/* <Form.Select
                            isInvalid={error.errorEmployee}
                            >
                            <option value="" disabled selected>Select Number of Employees</option>
                            <option value="1-10">1-10</option>
                            <option value="11-50">11-50</option>
                            <option value="51-100">51-100</option>
                            <option value="101-200">101-200</option>
                            <option value="201-500">201-500</option>
                            <option value="500 +">500 +</option>
                            </Form.Select> */}
                        <Select
                            placeholder="Select Number of Employees"
                            options={no_of_employee}
                            value={{
                                value: companyDetails.employees,
                                label: companyDetails.employees
                            }}
                            onChange={(e) => handleInputChange(e, 'employees')}
                        />
                        {error.errorEmployee && <p className='text-danger m-0'>{error.errorEmployee}</p>}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formIndustryType">
                        <Form.Label>Industry Type*</Form.Label>
                        {/* <Form.Control
                            type="text"
                            placeholder="e.g., Design/Software..."
                            isInvalid={error.errorIndustryType}
                            /> */}
                        <Select
                            options={industryType}
                            value={{
                                value: companyDetails.industryType,
                                label: companyDetails.industryType
                            }}
                            onChange={(e) => handleInputChange(e, 'industryType')}
                        />
                        {error.errorIndustryType && <p className='text-danger m-0'>{error.errorIndustryType}</p>}

                    </Form.Group>
                    <div className='w-100'>
                        <p className='m-0 mb-2'>Company Logo</p>
                        <div className="company-logo-upload d-flex align-items-center flex-wrap justify-content-between gap-2">
                            <div className='d-flex align-items-center justify-content-center flex-wrap gap-3'>
                                <div className="logo-box d-flex align-items-center justify-content-center">
                                    {imageUrl ? <img src={imageUrl} alt="profile-logo" /> : <FiCamera fontSize={35} />}
                                </div>
                                {!logoName ?
                                    <div className="logo-guidance">
                                        <h1>Upload Your Company Logo</h1>
                                        <p>Supported Formats: png, jpeg, up to 2 MB</p>
                                    </div> :
                                    <div className="logo-guidance">
                                        <h1>{logoName}</h1>
                                    </div>
                                }

                            </div>
                            <input className='d-none' ref={fileInputRef} type="file" onChange={imageUploadChange} />
                            <div onClick={handleImageUpload} className='uploadLogoCo flex-grow-1 flex-sm-grow-0'>Upload Logo</div>
                        </div>
                        {error.errorCompanyLogo && <p className='text-danger m-0'>{error.errorCompanyLogo}</p>}
                    </div>
                </div>
                <div className="rightSide-form-section">
                    <Form.Group className="mb-3" controlId="formDesignation">
                        <Form.Label>Owner Name*</Form.Label>
                        <Form.Control
                            type="text" placeholder="Type company’s owner name"
                            value={companyDetails.designation}
                            onChange={(e) => handleInputChange(e, 'designation')}
                            isInvalid={error.errorDesignation}
                        />
                        <Form.Control.Feedback type="invalid">
                            {error.errorDesignation}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Company Establish Year*</Form.Label>
                        {/* <Form.Select
                            className='company-establish-year-dropdown'
                            value={companyDetails.establishYear}
                            onChange={(e) => handleInputChange(e, 'establishYear')}
                            isInvalid={error.errorEstablishYear}    
                        >
                            <option value="" disabled selected>Select Company Establish Year</option>
                            {
                                yearList.map((data, index) => (
                                    <option key={index} value={data.value}>{data?.label}</option>
                                ))
                            }
                        </Form.Select> */}
                        <Select
                            options={yearList}
                            placeholder="Select Company Establish Year"
                            value={{
                                value: companyDetails.establishYear,
                                label: companyDetails.establishYear
                            }}
                            onChange={(e) => handleInputChange(e, 'establishYear')}
                        />
                        <Form.Control.Feedback type="invalid">
                            {error.errorEstablishYear}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formWebsiteUrl">
                        <Form.Label>Website URL*</Form.Label>
                        <Form.Control
                            type="text" placeholder="Enter your website URL"
                            value={companyDetails.websiteUrl}
                            onChange={(e) => handleInputChange(e, 'websiteUrl')}
                            isInvalid={error.errorWebsiteUrl}
                        />
                        <Form.Control.Feedback type="invalid">
                            {error.errorWebsiteUrl}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGstNumber">
                        <Form.Label>GST Number*</Form.Label>
                        <Form.Control
                            type="text" placeholder="Enter Company GST number"
                            value={companyDetails.gstNumber}
                            onChange={(e) => handleGSTInputChange(e, 'gstNumber')}
                            isInvalid={error.errorGstNumber || gstValidationError?.gstValErr}
                        />
                        <Form.Control.Feedback type="invalid">
                            {error.errorGstNumber}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {gstValidationError?.gstValErr}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formCompanyAddress">
                        <Form.Label>Company Address*</Form.Label>
                        <Form.Control
                            style={{ minHeight: '11.3rem' }}
                            className='text-area-custome'
                            as="textarea" rows={6}
                            placeholder="Type your company address here"
                            value={companyDetails.companyAddress}
                            onChange={(e) => handleInputChange(e, 'companyAddress')}
                            isInvalid={error.errorCompanyAdd}
                        />
                        <Form.Control.Feedback type="invalid">
                            {error.errorCompanyAdd}
                        </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className='d-block w-100'>
                    <div className='outer-container-coCrea d-flex flex-column justify-content-center'>
                        <Form.Group className="mb-3" controlId="formCompanyDescription">
                            <Form.Label>Company Description*</Form.Label>
                            <Form.Control
                                className='text-area-custome'
                                as="textarea" rows={5}
                                placeholder="Write about your company"
                                value={companyDetails.companyDescription}
                                onChange={(e) => handleInputChange(e, 'companyDescription')}
                                isInvalid={error.errorCompanyDescr}
                            />
                            <Form.Control.Feedback type="invalid">
                                {error.errorCompanyDescr}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <EventButton disabled={loader} className='d-flex justify-content-center align-items-center ' type='submit'>
                            {
                                loader ?
                                    <><Oval height="20" width="20" color='white' /> Submit</> : "Submit"
                            }
                        </EventButton>
                    </div>
                </div>
            </Form>
        </div>
    );
}
