import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useNavigate,
  Outlet,
} from "react-router-dom";
import Layout from "../User/component/Layout/Layout";
import Home from "../User/Pages/Home/Home";
import UserLogin from "../User/component/Auth/Login";
import UserRegistered from "../User/component/Auth/Registered";
import UserForgotPassword from "../User/component/Auth/ForgotPassword";
import UserGetStarted from "../User/component/Auth/GetStarted";
import UserEnterCode from "../User/component/Auth/EnterCode";
import SuccessfulSubmit from "../User/Pages/CreateProfile/SuccessfulSubmit";
import Feed from "../User/Pages/Feed/Feed";
import AppliedJob from "../User/Pages/AppliedJob/AppliedJob";
import SearchJob from "../User/Pages/SearchJob/SearchJob";
import ProfileStepper from "../User/Pages/CreateProfile/ProfileStepper";
import Chat from "../User/Pages/Chat/Chat";
import UserProfile from "../User/Pages/Profile/Profile";
import ApplyJob from "../User/Pages/ApplyJob/ApplyJob";
import Index from "../User/Pages/SingleJobDetails/Index";
import CreateProfile from "../Company/Pages/CreateProfile/CreateProfile";
import CompanyLayout from "../Company/Component/Layout/CompanyLayout";
import CreateJobPost from "../Company/Pages/CreatJob/JobPost";
import Applications from "../Company/Pages/Applications/Applications";
import Candidate from "../Company/Pages/Candidates/Candidate";
import CompanyProfile from "../Company/Pages/CompanyProfile/CompanyProfile";
import JobPost from "../Company/Pages/JobPost/Index";
import CompanyLogin from "../Company/Component/Auth/Login";
import CompanyRegistered from "../Company/Component/Auth/Registered";
import CompanyForgotPassword from "../Company/Component/Auth/ForgotPassword";

// Helper functions
const getAccessTokenForUser = () => localStorage.getItem("userToken");
const isAuthenticatedForUser = () => !!getAccessTokenForUser();
const getUserRole = () => localStorage.getItem("userRole");
const isUserProfileComplete = () => localStorage.getItem("isprofileUserComplete") === "true";

const getCompanyRole = () => localStorage.getItem("companyRole");
const getAccessTokenForCompany = () => localStorage.getItem("token");
const isAuthenticatedForCompany = () => !!getAccessTokenForCompany();
const isCompanyProfileComplete = () => localStorage.getItem("isprofileComplete") === "true";

// User Protected Route
export const UserProtectedRoute = ({ children }) => {
  const role = getUserRole();
  const navigate = useNavigate()
  let u_token = localStorage.getItem('userToken')

  useEffect(() => {
    if (!u_token) {
      navigate("/user/login");
    } else if (isUserProfileComplete() && window.location.pathname === "/user/create-profile") {
      navigate("/user/user-profile");
    } else if (!isUserProfileComplete() && window.location.pathname !== "/user/create-profile") {
      navigate("/user/create-profile");
    }
  }, [ navigate, u_token]);

  if(!localStorage.getItem('userToken')){
    return <Navigate to="/user/login" />
  }

  if (isUserProfileComplete() && window.location.pathname === "/user/create-profile") {
    return <Navigate to="/user/user-profile" />;
  }

  if (!isUserProfileComplete() && window.location.pathname !== "/user/create-profile") {
    return <Navigate to="/user/create-profile" />;
  }

  return isAuthenticatedForUser() && role === "user" ? (
  // return isAuthenticatedForUser() ? (
    children
  ) : (
    <Navigate to="/user/login" />
  );
};

// Company Protected Route
export const CompanyProtectedRoute = ({ children }) => {
  const navigate = useNavigate()
  const role = getCompanyRole();
  let c_company = localStorage.getItem('token')

  useEffect(() => {
    if (!c_company) {
      navigate("/company/login");
    } else if (isCompanyProfileComplete() && window.location.pathname === "/company/create-profile") {
      navigate("/company/profile");
    } else if (!isCompanyProfileComplete() && window.location.pathname !== "/company/create-profile") {
      navigate("/company/create-profile");
    }
  }, [ navigate, c_company]);

  if(!localStorage.getItem("token")){
    return <Navigate to="/company/login" />
  }

  if (isCompanyProfileComplete() && window.location.href.includes("/company/create-profile")) {
    return <Navigate to="/company/profile" />;
  }

  if (!isCompanyProfileComplete() && window.location.pathname !== "/company/create-profile") {
    return <Navigate to="/company/create-profile" />;
  }

  return isAuthenticatedForCompany() && role === "company" ? (
  // return isAuthenticatedForCompany() ? (
    children
  ) : (
    <Navigate to="/company/login" />
  );
};
