import React, { useEffect, useRef, useState } from 'react'
import dummyCompanyLogo from '../../../../assets/dummyProfileImage.png'
import { FaLocationDot } from 'react-icons/fa6'
import bagImage from '../../../../assets/bag-solid.png'
import cashMultiple from '../../../../assets/cash-multiple.png'
import { Pagination } from 'antd';
import { FaAngleLeft } from "react-icons/fa6";
import './SimilarJob.css'
import { PaginationBtn } from '../../../component/ReUseComps/Button'
import { FaAngleRight } from 'react-icons/fa'
import axios from 'axios'
import { URLS } from '../../../../Utils/App_urls'
import { useNavigate, useParams } from 'react-router-dom'
import { GoPlus } from 'react-icons/go'
import { Oval } from 'react-loader-spinner'

export default function SimilarJob() {
    const navigate = useNavigate()
    const paramId = useParams();
    const [paginationBtnArr, setpaginationBtnArr] = useState([1, 2, 3, 4, 5])
    const [allSimilarJobs, setAllSimilarJobs] = useState([])
    const dummySkillArr = ['Full Time', 'Part-Time', 'Contract']
    const [clipArrLength, setClipArrLength] = useState({ min: 0, max: 10 })

    const getSimillarJob = () => {
        axios.get(`${URLS.getAllJob}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
        })
            .then((res) => {
                setAllSimilarJobs(res?.data?.result)
            })
            .catch((err) => { })
    }


    const handleIncPagination = () => {
        setpaginationBtnArr(paginationBtnArr.map((item, _) => item + 1))
        setClipArrLength({ min: (paginationBtnArr[paginationBtnArr.length - 1] * 10) - 10, max: paginationBtnArr[paginationBtnArr.length - 1] * 10 })

    }
    const handleDecPagination = () => {
        if (paginationBtnArr[0] > 1) {
            setpaginationBtnArr(paginationBtnArr.map((item, _) => item - 1))
        }
        paginationBtnArr[0] >= 0 && setClipArrLength({ min: (paginationBtnArr[0] * 10) - 10, max: paginationBtnArr[0] * 10 })

    }

    const convertHtmlToText = (htmlString) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;
        return tempElement.textContent || tempElement.innerText || "";
    };

    const postedDate = (data) => {
        const firstDate = new Date(data);
        const currentDate = new Date();
        const timeDifference = currentDate - firstDate;
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
        return daysDifference > 0 ? `${daysDifference} Day${daysDifference > 1 ? 's' : ''} ago` : 'Posted Today';
    }

    const handlePaginationSize = (pgNo) => {
        let max = pgNo * 10;
        let min = max - 10 >= 0 ? max - 10 : 0;
        setClipArrLength({ min: min, max: max })
    }

    const redirectTojobDetails = (id) => {
        navigate(`/user/find-job/job-details/${id}`, {
            state: { DATA: id }
        })
    }

    useEffect(() => {
        getSimillarJob()
    }, [])
    console.log('allSimilarJobs::', allSimilarJobs)
    return (
        <>
            {allSimilarJobs?.length <= 0 && <div className='d-flex align-items-center justify-content-center'><Oval width="50" height="50px" color='#3284FF' /></div>}
            <div className="similar-job-main lkasjdoqi d-flex flex-column px-2 gap-3">
                {
                    allSimilarJobs.map((job, index) => (
                        (index >= clipArrLength.min && index < clipArrLength.max) &&
                        <div key={index} onClick={() => redirectTojobDetails(job?._id)} className={`similar-job-card d-flex flex-column gap-3 ${paramId?.id === job?._id && 'active_similar_job'}`}>
                            <div className="similar-job-header d-flex align-items-start justify-content-between flex-wrap">
                                <div className='d-flex align-items-center gap-2'>
                                    <div className="compy-logo-box">
                                        <img src={job?.companyProfileDetail?.[0]?.companylogo || dummyCompanyLogo} alt="Company Logo" />
                                    </div>
                                    <div className='compy-title-descr'>
                                        <h1>{job?.title}</h1>
                                        <p className='m-0'>{job?.companyProfileDetail?.[0]?.companyName}</p>
                                        <a target='_blank' href={job?.companyProfileDetail?.[0]?.weburl}>{job?.companyProfileDetail?.[0]?.weburl}</a>
                                    </div>
                                </div>
                                <p className='m-0'>{postedDate(job?.createdAt?.split('T'))}</p>
                            </div>
                            <div className='compay-benif d-flex align-items-center justify-content-start flex-wrap gap-4'>
                                <div><img src={bagImage} alt="bag solid image" /> <span>{job?.experience}</span></div>
                                {
                                    job?.salaryType === "Fixed Salary" &&
                                    <div><img src={cashMultiple} alt="cash multiple" /> <span>{job?.FixedSalary}k</span></div>
                                }
                                {
                                    job?.salaryType === "Salary Range" &&
                                    <div><img src={cashMultiple} alt="cash multiple" /> <span>{job?.minSalary}k - {job?.maxSalary}k</span></div>
                                }
                                {
                                    job?.salaryType === "Not Disclose" &&
                                    <div><img src={cashMultiple} alt="cash multiple" /> <span>{job?.salaryType}</span></div>
                                }
                                {/* <div><img src={cashMultiple} alt="cash multiple" /><span>{job.salary}</span></div> */}
                                <div><FaLocationDot fontSize={18} /><span>{job?.workPlace}</span></div>
                            </div>
                            <div className="highlight-skills d-flex align-items-center flex-wrap gap-3">
                                {job.skills?.slice(0, 3).map((skill, idx) => (
                                    <div key={idx}>{skill}</div>
                                ))}
                                {job?.skills?.length > 3 && <div><GoPlus /> {job?.skills?.length - 3} More</div>}
                            </div>
                            {
                                job?.jobSummary ? <div className='job-details-descrip'>
                                    {job?.jobSummary?.slice(0, 120)}
                                    {job?.jobSummary?.length > 120 && '...'}
                                    {/* <div dangerouslySetInnerHTML={{ __html: convertHtmlToText(job?.jobSummary).slice(0, 120) + '...' }} /> */}
                                </div>
                                    : <div className='job-details-descrip'>
                                        <div dangerouslySetInnerHTML={{ __html: convertHtmlToText(job?.jobSummary).slice(0, 120) + '...' }} />
                                    </div>
                            }
                        </div>
                    ))}
                {
                    allSimilarJobs?.length > 0 && <div className="footer-pagination d-flex align-items-center justify-content-center pb-3">
                        {/* <button className='pagination-btn'>1</button> */}
                        <div className='d-flex align-items-center gap-2'>
                            <PaginationBtn
                                className='pagination-btn'
                                onClick={handleDecPagination}
                                disabled={paginationBtnArr[0] === 1}>
                                <FaAngleLeft fontSize={20} />
                                Previous
                            </PaginationBtn>
                            {
                                paginationBtnArr.map((data, idx) => (
                                    <PaginationBtn
                                        key={idx}
                                        onClick={() => handlePaginationSize(data)}
                                        disabled={Math.ceil(data > Math.ceil(allSimilarJobs.length / 10))}
                                        className={`pagination-btn ${clipArrLength?.max / 10 === data && 'activePageBtn_det'}`}
                                    >
                                        {data}
                                    </PaginationBtn>
                                ))
                            }
                            <PaginationBtn
                                className='pagination-btn'
                                onClick={handleIncPagination}
                                disabled={paginationBtnArr[paginationBtnArr?.length - 1] > Math.ceil(allSimilarJobs.length / 10)}
                            >   
                                Next
                                <FaAngleRight fontSize={20} />
                            </PaginationBtn>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}
