import React, { useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import light_archive_outline from '../../../assets/archiveColor.png'
import { IoBag, IoHelpCircleOutline, IoNotifications } from "react-icons/io5";
import { errorPopup, successPopup } from '../../../Utils/ToasterMessage';
import profileLight from '../../../assets/iconamoon_profile-light.png'
import dummyProfileImage from '../../../assets/dummyProfileImage.png'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import formkit_help from '../../../assets/formkit_help.png'
import kaamwalaLogo from '../../../assets/KAAMVALALogo.png'
import { BiSolidCreditCardFront } from 'react-icons/bi';
import { CiLogout, CiSettings } from "react-icons/ci";
import UserContext from '../ContextApi/UserContext';
import { MdOutlineArchive } from "react-icons/md";
import { IoLogOutOutline } from "react-icons/io5";
import Dropdown from 'react-bootstrap/Dropdown';
import { RiSearch2Fill } from "react-icons/ri";
import { URLS } from '../../../Utils/App_urls';
import Navbar from 'react-bootstrap/Navbar';
import { FaBookmark, FaUserAlt } from "react-icons/fa";
import Loader from '../Loader/Loader';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import axios from 'axios';
import './Navbar.css'

export default function NavBar() {
    const { openDrawer, setOpenDrawer, profileImageUrl, setProfileImageUrl, userProfileComaplete, getUserDetails } = useContext(UserContext)
    const [userDetails, setUserDetails] = useState({})
    const [showDropdown, setShowDropdown] = useState(false);
    const location = useLocation()
    const [userCred, setUserCred] = useState(true)
    const navigate = useNavigate()
    const [expandedNav, setExpandedNav] = useState(false)
    const [loader, setLoader] = useState(false)
    const [isLogin, setIsLogin] = useState(false)

    let Authtoken = localStorage?.getItem('userToken') || localStorage.getItem('auth0UserId') || '';

    const handleToggle = () => {
        setShowDropdown(!showDropdown);
    };

    const getUserProfileData = () => {
        axios.get(`${URLS.get_userProfile}`, {
            headers: {
                Authorization: `Bearer ${localStorage?.getItem('userToken')}`
            }
        })
            .then((res) => {
                console.log('resProfile', res)
                setUserDetails(res?.data?.data)
                setProfileImageUrl(res?.data?.data?.profile)
            })
            .catch((err) => console.log(err))
    }

    const handleLogOut = () => {
        setLoader(true)
        axios.post(`${URLS.logout}`, {
            "refreshToken": `${localStorage.getItem('acessToken')}`
        })
            .then(res => {
                successPopup(res?.data?.message)
                localStorage.removeItem('userToken')
                localStorage.removeItem('userRole')
                localStorage.removeItem('isprofileUserComplete')
                setLoader(false)

                setTimeout(() => {
                    navigate('/user/login')
                }, 500)
            })
            .catch((err) => {
                console.log(err)
                errorPopup(err?.response?.data?.message);
                setLoader(false)
            })
    }

    const checkLocation = () => {
        const locationArr = location.pathname.split('/');
        return locationArr || '';
    };

    useEffect(() => {
        getUserProfileData()
        getUserDetails()
    }, [])

    return (
        <>
            {loader && <Loader />}
            <div className='main-navbar'>
                <Navbar collapseOnSelect expanded={expandedNav} expand="lg" className="bg-body-tertiary m-0 pt-3 w-100">
                    <Container fluid>
                            <div className='d-flex'>
                                <Navbar.Toggle onClick={() => setExpandedNav(prev => !prev)} aria-controls="navbarScroll" />
                            </div>
                            <Navbar.Brand className={`${!userProfileComaplete && 'disable-items'}`} to="/home">
                                <img className='topnavlogo' onClick={() => navigate('/user/find-job')} src={kaamwalaLogo} alt="kaamwalaLogo" />
                            </Navbar.Brand>
                        {/* <div className='d-flex align-items-center flex-row-reverse gap-3'>
                        </div> */}
                        <div className='smallDevice_icons'>
                            <div className='top-nav-bar'>
                                {/* <span onClick={() => setOpenDrawer(true)} className={`menu-icon-navbar`}><IoIosMenu fontSize={45} /></span> */}
                                <div className='h-100 d-flex align-items-center gap-2'>
                                    <div className={`login-btn ksjdhisdn ${!userProfileComaplete && 'disable-items'}`}>
                                        <IoNotifications fontSize={24} />
                                    </div>
                                    <Dropdown className={`proofileDrop-menu`} show={showDropdown} onToggle={handleToggle}>
                                        <div id="dropdown-basic" className='login-btn ksjdhisdn'
                                            onClick={handleToggle}>
                                            {profileImageUrl ? <img className='profile-iconNav' src={profileImageUrl} alt="profile Image" /> : <FaUserAlt fontSize={24} />}
                                        </div>
                                        <Dropdown.Menu className='profile-menu-items'>
                                            <div className='user_profile_iconsBox d-flex flex-column'>
                                                <Dropdown.Item onClick={() => navigate('user-profile')} className={`${!userProfileComaplete && 'disable-items'} prifle_user_items`} >
                                                    {/* <div><FaUserAlt fontSize={16} color='#3284FF' /></div> */}
                                                    <div><img src={profileLight} alt="profileLight" /></div>
                                                    <span>Profile</span>
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => navigate('user-profile')} className={`${!userProfileComaplete && 'disable-items'} prifle_user_items`} >
                                                    <div><img src={light_archive_outline} alt="light_archive_outline" /></div>
                                                    <span>Archived Jobs</span>
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => navigate('user-profile')} className={`${!userProfileComaplete && 'disable-items'} prifle_user_items`} >
                                                    <div><CiSettings fontSize={28} color='#3284FF' /></div>
                                                    <span>Setting</span>
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => navigate('user-profile')} className={`${!userProfileComaplete && 'disable-items'} prifle_user_items`} >
                                                    <div><img src={formkit_help} alt="formkit_help" /></div>
                                                    <span>Help Centre</span>
                                                </Dropdown.Item>
                                                <div className='line_dividor' />
                                                <Dropdown.Item className='logout_nav_item' onClick={handleLogOut}>
                                                    <div><IoLogOutOutline color='#F06548' fontSize={24} /></div>
                                                    <span> Log Out</span>
                                                </Dropdown.Item>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </div>
                            </div>
                        </div>
                        <Navbar.Collapse id="navbarScroll" className='align-items-center'>
                            <Nav className="my-2 my-lg-0 navUIList" navbarScroll >
                                <Link
                                    className={`items-hover ${!userProfileComaplete && 'disable-items'}`}
                                    id={`${checkLocation().includes('find-job') && 'active-navItema'}`}
                                    onClick={() => setExpandedNav(false)} to="find-job">
                                    <div> <RiSearch2Fill fontSize={20} /><span>Find Job</span> </div>
                                </Link>
                                <Link
                                    className={`items-hover ${!userProfileComaplete && 'disable-items'}`}
                                    id={`${checkLocation().includes('applied-job') && 'active-navItema'}`}
                                    onClick={() => setExpandedNav(false)} to="applied-job">
                                    <div><IoBag fontSize={20} /> <span>Applied Job</span></div>
                                </Link>
                                <Link
                                    className={`items-hover ${!userProfileComaplete && 'disable-items'}`}
                                    id={`${checkLocation().includes('suggested-jobs') && 'active-navItema'}`}
                                    onClick={() => setExpandedNav(false)} to="suggested-jobs">
                                    <div><BiSolidCreditCardFront fontSize={24} /> <span>Suggested Jobs</span></div>
                                </Link>
                                <Link
                                    className={`items-hover ${!userProfileComaplete && 'disable-items'}`}
                                    id={`${checkLocation().includes('savedJobs') && 'active-navItema'}`}
                                    onClick={() => setExpandedNav(false)} to="savedJobs">
                                    <div><FaBookmark fontSize={18} /> <span>Saved Jobs</span></div>
                                </Link>
                            </Nav>
                            <div className='top-nav-bar responsiveUserHodeIcons'>
                                <div className='h-100 d-flex align-items-center gap-2'>
                                    <div className={`login-btn jashdia ksjdhisdn ${!userProfileComaplete && 'disable-items'}`}>
                                        <IoNotifications fontSize={24} color='#3283FC' />
                                    </div>
                                    <Dropdown className={`proofileDrop-menu`} show={showDropdown} onToggle={handleToggle}>
                                        <div id="dropdown-basic" className='login-btn ksjdhisdn'
                                            onClick={handleToggle}>
                                            {profileImageUrl ? <img className='profile-iconNav' src={profileImageUrl} alt="profile Image" /> : <FaUserAlt fontSize={24} />}
                                        </div>
                                        <Dropdown.Menu className='profile-menu-items'>
                                            <div className='user_profile_iconsBox d-flex flex-column'>
                                                <Dropdown.Item onClick={() => navigate('user-profile')} className={`${!userProfileComaplete && 'disable-items'} prifle_user_items`} >
                                                    <div><img src={profileLight} alt="profileLight" /></div>
                                                    <span>Profile</span>
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => navigate('user-profile')} className={`${!userProfileComaplete && 'disable-items'} prifle_user_items`} >
                                                    <div><img src={light_archive_outline} alt="light_archive_outline" /></div>
                                                    <span>Archived Jobs</span>
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => navigate('user-profile')} className={`${!userProfileComaplete && 'disable-items'} prifle_user_items`} >
                                                    <div><CiSettings fontSize={28} color='#3284FF' /></div>
                                                    <span>Setting</span>
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => navigate('user-profile')} className={`${!userProfileComaplete && 'disable-items'} prifle_user_items`} >
                                                    <div><img src={formkit_help} alt="formkit_help" /></div>
                                                    <span>Help Centre</span>
                                                </Dropdown.Item>
                                                <div className='line_dividor' />
                                                <Dropdown.Item className='logout_nav_item' onClick={handleLogOut}>
                                                    <div><IoLogOutOutline color='#F06548' fontSize={24} /></div>
                                                    <span> Log Out</span>
                                                </Dropdown.Item>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </div>
                            </div>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </>
    )
}
