import React, { useContext, useEffect, useState } from 'react'
import './Index.css'
import EmptyJob from './EmptyJob'
import JobPostCards from './JobPostCards/JobPostCards'
import CompanyContext from '../../Component/ContextAPI/CompanyContext'
import axios from 'axios';
import { COMPANY_URLS } from '../../../Utils/Company_App_Urls';


export default function Index() {
    const { isJobCreated, needTorender } = useContext(CompanyContext);
    const [isJob, setIsJob] = useState(0)

    const getAllJobPost = () => {
        axios.get(`${COMPANY_URLS.getAllJobs}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setIsJob(res?.data?.data?.length)
            })
            .catch((err) => {})
    }

    useEffect(() => {
        getAllJobPost()
    }, [isJobCreated, needTorender])

    return (
        <>
            <div className='job_post_main_index'>
                {isJob ? <JobPostCards /> : <EmptyJob />}
                {/* {!isJobCreated && <EmptyJob />} */}
            </div>
        </>
    )
}
