import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import kaamwalaLogo from '../../../assets/KAAMVALALogo.png';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CiLogout } from "react-icons/ci";
import { FaUserAlt } from "react-icons/fa";
import { IoDocumentTextOutline, IoNotifications } from "react-icons/io5";
import { PiBagSimpleFill } from "react-icons/pi";
import { BsPeopleFill } from 'react-icons/bs';
import { errorPopup, successPopup } from '../../../Utils/ToasterMessage';
import CompanyContext from '../ContextAPI/CompanyContext';
import { URLS } from '../../../Utils/App_urls';
import './Navbar.css';
import { COMPANY_URLS } from '../../../Utils/Company_App_Urls';

export default function NavBar() {
    const location = useLocation();
    const navigate = useNavigate();
    const { setLoader, isCompanyProfileComplate, needTorender, profileLogo, setProfileLogo } = useContext(CompanyContext);
    const [companyLogo, setCompanyLogo] = useState('')
    const [showDropdown, setShowDropdown] = useState(false);
    const [expandedNav, setExpandedNav] = useState(false);

    const handleToggle = () => {
        setShowDropdown(!showDropdown);
    };

    const getCompanyDetails = () => {
        axios.get(`${COMPANY_URLS.get_profile}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })
            .then((res) => {
                setProfileLogo(res?.data?.data?.companylogo)
            })
            .catch((err) => { })
    }

    const handleLogOut = () => {
        setLoader(true);
        axios.post(`${COMPANY_URLS.logout}`, {
            "refreshToken": `${localStorage.getItem('acessToken')}`
        })
            .then(res => {
                successPopup(res?.data?.message);
                localStorage.removeItem('token');
                localStorage.removeItem('companyRole');
                localStorage.removeItem('isprofileComplete');
                localStorage.removeItem('registeredEmail');
                setLoader(false);
                navigate('/company/login');
            })
            .catch((err) => {
                errorPopup(err?.response?.data?.message);
                setLoader(false);
            });
    };

    const checkLocation = () => {
        const locationArr = location.pathname.split('/');
        return locationArr || '';
    };

    const handleHomeLogoClick = () => {
        window.location.reload()
        navigate('/company/job-post')
    }

    useEffect(() => {
        getCompanyDetails();
    }, [needTorender])

    useEffect(() => {
        checkLocation();
    }, []);
    console.log('needTorender::', needTorender)
    console.log('profileLogo::', profileLogo)

    return (
        <>
            <div className='main-navbar'>
                <Navbar collapseOnSelect expanded={expandedNav} expand="lg" className="bg-body-tertiary m-0 w-100">
                    <Container fluid>
                        <Navbar.Brand className={`${!isCompanyProfileComplate && 'disabled-navItems'}`}>
                            <img onClick={() => handleHomeLogoClick()} src={kaamwalaLogo} alt="kaamwalaLogo" />
                        </Navbar.Brand>
                        <div className='top-nav-bar'>
                            <div className='h-100 d-flex align-items-center gap-2'>
                                <div className={`${!isCompanyProfileComplate && 'disabled-navItems'} login-btn user-avatar`}>
                                    <IoNotifications fontSize={24} />
                                </div>
                                <Dropdown className={`profile-drop-menu`} show={showDropdown} onToggle={handleToggle}>
                                    <div className={`login-btn user-avatar`} id="dropdown-basic" onClick={handleToggle}>
                                        {profileLogo ? <img className='profile-iconNav' src={profileLogo} alt="profile Image" />
                                            : <FaUserAlt fontSize={24} />}
                                    </div>
                                    <Dropdown.Menu className='profile-menu-items'>
                                        <Dropdown.Item className={`${!isCompanyProfileComplate && 'disabled-navItems'}`} onClick={() => navigate('profile')} ><FaUserAlt fontSize={16} />Profile</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={handleLogOut}><CiLogout fontSize={18} /> Log Out</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </Container>
                </Navbar>
            </div>
        </>
    );
}
